import React from 'react';
import SignupForm from './SignupForm';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import './LoginSystem.css';
import { getAuth } from '../../js/actions/index';

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

const mapDispatchToProps = {
  getAuth
}


class ConnectedSignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user
    };
  }

  render() {

    return (
      this.props.user.loggedIn
        ? <Redirect to='/' />
        : <div className="main">
          <div className="container__small">
            <h1>Załóż konto</h1>

            <SignupForm />
          </div>
        </div>
    );
  }
}

const SignupPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedSignupPage);

export default SignupPage;