import React from 'react';

const ArrowClockIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="298.104" height="298.102" viewBox="0 0 298.104 298.102">
  <path className={`${classNamePath || ""}`} id="history" d="M306.1,156.769c.152,82.122-66.821,149.275-148.943,149.333a148.427,148.427,0,0,1-93.651-33A14.414,14.414,0,0,1,62.4,251.7l6.772-6.772a14.45,14.45,0,0,1,19.167-1.192A110.593,110.593,0,1,0,81.283,76.475l30.5,30.5a9.616,9.616,0,0,1-6.8,16.416H17.616A9.616,9.616,0,0,1,8,113.778V26.407a9.616,9.616,0,0,1,16.416-6.8L54.089,49.281A149.049,149.049,0,0,1,306.1,156.769ZM197.372,204.12l5.9-7.591a14.424,14.424,0,0,0-2.53-20.242l-24.462-19.026V94.546a14.424,14.424,0,0,0-14.424-14.424h-9.616a14.424,14.424,0,0,0-14.424,14.424v81.528l39.312,30.576A14.425,14.425,0,0,0,197.372,204.12Z" transform="translate(-8 -8)"/>
</svg>);

export default ArrowClockIcon;