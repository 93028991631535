import React from 'react';
import { connect } from 'react-redux';
import { pickupFormUpdate, place, refreshCode } from '../../../js/actions/index';
import UserIcon from '../../Icons/User';
import PhoneIcon from '../../Icons/Phone';
import MailIcon from '../../Icons/Mail';
import InfoIcon from '../../Icons/Info';
import { Link } from 'react-router-dom';


class PickupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      phone: '',
      rodo: false,
      email: '',
      additionalInfo: '',
      place: '',
      cutlery: false,
      local: '',

      errors: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePlace = this.handlePlace.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCheckClick = this.handleCheckClick.bind(this);
    this.handleCheckCutlery = this.handleCheckCutlery.bind(this);
  }

  componentDidMount() {
    this.setState({ local: this.props.activeLocalization });
    this.setState({ place: this.props.placeProps });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.placeProps !== this.props.placeProps) {
      let orderPrice = Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0));
      let deliveryPrice = Number(this.props.deliveryPrice);
      let wrapPrice = Number(this.props.wrapPrice);
      this.props.refreshCode(this.props.code, orderPrice, deliveryPrice, wrapPrice, this.props.order, this.state.local);
    }
  }

  handleCheckClick() {
    this.setState({ rodo: !this.state.rodo }, () => { this.props.pickupFormUpdate(this.state) });
  }

  handleCheckCutlery() {
    this.setState({ cutlery: !this.state.cutlery }, () => { this.props.pickupFormUpdate(this.state) });
  }

  handlePlace(type) {
    if (type !== this.state.place) {
      this.setState({ place: type });
      this.props.place(type);

      let orderPrice = Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0));
      let deliveryPrice = Number(this.props.deliveryPrice);
      let wrapPrice = Number(this.props.wrapPrice);
      this.props.refreshCode(this.props.code, orderPrice, deliveryPrice, wrapPrice, this.props.order, this.state.local);
    }
    else {

    }
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value.trim() });
  }

  handleUpdate(event) {
    this.setState({ [event.target.id]: event.target.value.trim() });
    this.props.pickupFormUpdate(this.state);
  }

  render() {
    const { name, surname, phone, rodo, email, additionalInfo, cutlery, errors } = this.state;
    return (
      <div>
        <div className="choose__container">
          <div className={this.state.place === "out" ? "choose__button checked" : "choose__button"} onClick={() => this.handlePlace("out")}>
            Na wynos
          </div>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div>
            <div className="form__group__checkout">
              <input
                placeholder="Imię"
                className="form__control__checkout"
                type="text"
                id="name"
                value={name}
                onChange={this.handleChange}
                error={errors.name}
                onBlur={this.handleUpdate}
              />
              <div className="cart__icon__box">
                <UserIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>
              <p className="form-error"> {errors.name ? errors.name : ''}</p>
            </div>
            <div className="form__group__checkout">
              <input
                placeholder="Nazwisko"
                className="form__control__checkout"
                type="text"
                id="surname"
                value={surname}
                onChange={this.handleChange}
                error={errors.surname}
                onBlur={this.handleUpdate}
              />
              <div className="cart__icon__box">
                <UserIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>
              <p className="form-error"> {errors.surname ? errors.surname : ''}</p>
            </div>
            <div className="form__group__checkout">
              <input
                placeholder="Email"
                className="form__control__checkout"
                type="text"
                id="email"
                value={email}
                onChange={this.handleChange}
                error={errors.email}
                onBlur={this.handleUpdate}
              />
              <div className="cart__icon__box">
                <MailIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>
              <p className="form-error"> {errors.email ? errors.email : ''}</p>
            </div>
            <div className="form__group__checkout">
              <input
                placeholder="Telefon"
                className="form__control__checkout"
                type="tel"
                id="phone"
                value={phone}
                onChange={this.handleChange}
                error={errors.phone}
                onBlur={this.handleUpdate}
              />
              <div className="cart__icon__box">
                <PhoneIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>
              <p className="form-error"> {errors.phone ? errors.phone : ''}</p>
            </div>
            <div className="form__group__checkout">
              <input
                placeholder="Dodatkowe informacje"
                className="form__control__checkout"
                type="text"
                id="additionalInfo"
                value={additionalInfo}
                onChange={this.handleChange}
                error={errors.additionalInfo}
                onBlur={this.handleUpdate}
              />
              <div className="cart__icon__box">
                <InfoIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>

              <p className="form-error"> {errors.additionalInfo ? errors.additionalInfo : ''}</p>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                id="cutlery"
                checked={cutlery}
                onChange={this.handleCheckCutlery}
              />
              <label htmlFor="cutlery" style={{ paddingLeft: '10px' }}>Zapakować sztućce, sól i serwetki?</label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                id="rodo"
                checked={rodo}
                onChange={this.handleCheckClick}
                error={errors.rodo}
              />
              <label htmlFor="rodo">Zapoznałam(em) się i akceptuję <Link to="/regulamin">Regulamin</Link> i <Link to="/polityka-prywatnosci">Politykę Prywatności</Link>.</label>
            </div>
          </div>
        </form>
      </div>
    )
  }

}

function mapStateToProps(state) {
  return {
    placeProps: state.place,
    code: state.usedCode,
    deliveryPrice: state.deliveryPrice,
    price: state.price,
    wrapPrice: state.wrapPrice,
    order: state.order,
    activeLocalization: state.activeLocalization
  };
}

function mapDispatchToProps(dispatch) {
  return {
    pickupFormUpdate: data => dispatch(pickupFormUpdate(data)),
    place: data => dispatch(place(data)),
    refreshCode: (code, orderPrice, deliveryPrice, wrapPrice, order, local) => dispatch(refreshCode(code, orderPrice, deliveryPrice, wrapPrice, order, local))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupForm);
