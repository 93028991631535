import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateInput(data) {
  let errors = {};

  if (data.rodo === false) {
    errors.rodo = 'Zaakcecptowanie polityki jest wymagane!';
  }

  if (Validator.isEmpty(data.name)) {
    errors.name = 'Pole imię jest wymagane!';
  }

  if (!Validator.isAlpha(data.name, ['pl-PL']) && !Validator.isEmpty(data.name)) {
    errors.name = 'Podane imię jest nieprawidłowe!';
  }

  if (Validator.isEmpty(data.surname)) {
    errors.surname = 'Polę nazwisko jest wymagane!';
  }

  if (!Validator.isAlpha(data.surname, ['pl-PL'], { ignore: '- /' }) && !Validator.isEmpty(data.surname)) {
    errors.surname = 'Podane nazwisko jet nieprawidłowe!';
  }

  if (Validator.isEmpty(data.phone)) {
    errors.phone = 'Polę numer telefonu jest wymagane!';
  }

  if (!Validator.isNumeric(data.phone) && !Validator.isEmpty(data.phone)) {
    errors.phone = 'Podany numer jest nieprawidłowy!';
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = 'Pole email jest wymagane!';
  }

  if (!Validator.isEmail(data.email) && !Validator.isEmpty(data.email)) {
    errors.email = 'Podany email jest nieprawidłowy!';
  }

  if (Validator.isEmpty(data.password)) {
    errors.password = 'Pole hasło jest wymagane!';
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}