import {
  DATA_LOADED,
  SIGNUP_SUCCESS, SIGNUP_ERROR,
  LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT,
  AUTH_SUCCESS, AUTH_ERROR,
  CATEGORIES_LOADING, CATEGORIES_SUCCESS, CATEGORIES_ERROR, MENU_ITEMS_LOADED,
  ADD_TO_CART, DELETE_FROM_CART, CODES_LOADED, CODES_LOADED_ERR, REALIZATION_CHANGE, REALIZATION_TIME_CHANGE, REALIZATION_TIME_VALUE_CHANGE, AMOUNT_ADD, AMOUNT_DELETE,
  DELIVERY_DATA_UPDATE, DELIVERY_FORM_UPDATE, PICKUP_FORM_UPDATE, PAYMENT_CHANGE, GET_PAYMENT_METHODS,
  SET_ORDER_ID, ORDER_FETCH_LOADING, PLACE_CHANGE, COPY_ORDERS, GET_ORDER_HISTORY_LOADING, GET_ORDER_HISTORY_SUCCESS,
  GET_ORDER_HISTORY_ERROR, ORDER_FETCH_SUCCESS, ORDER_FETCH_ERROR, UPDATE_ORDER, CHECK_STATUS, CHECK_STATUS_ERROR,
  GET_OPEN_HOURS_LOADING, GET_OPEN_HOURS_SUCCESS, GET_OPEN_HOURS_ERROR, CLEAR_ACTIVE_ORDER, GET_MEALS_OPTIONS_CATEGORIES, GET_MEALS_OPTIONS,
  GET_LOCALIZATIONS_LOADING, GET_LOCALIZATIONS_SUCCESS, GET_LOCALIZATIONS_ERROR, GET_INFOS_LOADING, GET_INFOS_SUCCESS, GET_INFOS_ERROR,
  CHANGE_LOCALIZATION, PROMOPRODUCT_DELETE,
} from "../constants/action-types";


let user = JSON.parse(localStorage.getItem('user'));
//console.log('reducer', user);
const initialState = {
  remoteArticles: [],
  signupAlert: [],
  loginAlert: [],
  user: user ? { loggedIn: true, user } : { loggedIn: false, user: {} },
  menuItems: [],
  promoCode: '',
  paymentMethods: [],

  mealsOptionsCategories: [],
  mealsOptions: [],

  //Order state
  orderNumber: '',

  user_id: '',

  name: '',
  surname: '',
  email: '',
  phone: '',
  rodo: false,
  cutlery: false,

  order: [],

  price: 0,
  deliveryPrice: 0,
  finalPrice: 0,
  wrapPrice: 0,
  wrapPriceActual: 0,

  distance: 0,
  paymentType: '',
  paymentTypeName: '',
  oldPrice: null,
  promo: false,
  usedCode: "",
  promoType: null,

  building_number: '',
  street: '',
  locality: '',
  postal_code: '',
  local: '',

  orderTime: '',
  status: '',
  payment_state: '',
  additionalInfo: '',

  place: "out",
  realization: "pick-up", //sposób dostawy
  realizationTime: "now",
  realizationTimeValue: 0,

  newOrderId: '',

  statusCheckedId: "",

  openhoursLoading: false,
  openhoursError: '',
  openhours: [],

  orderLoading: false,
  orderError: '',
  activeOrder: [],
  activeOrderId: '',

  categoriesLoading: false,
  categoriesError: '',
  categories: [],

  vicinity: '',

  historyOrdersLoading: false,
  historyOrdersError: '',
  historyOrders: [],

  localizationsLoading: false,
  localizationsError: '',
  localizations: [],
  activeLocalization: ' ',

  infos: [],
  infosLoading: false,
  infosError: '',

  codeError: '',

};

//console.log('initial', initialState.user);

function rootReducer(state = initialState, action) {
  if (action.type === DATA_LOADED) {
    return Object.assign({}, state, {
      remoteArticles: action.payload
    });
  }

  if (action.type === SIGNUP_SUCCESS) {
    //console.log(action.payload);
    return Object.assign({}, state, {
      signupAlert: action.payload
    });
  }
  if (action.type === SIGNUP_ERROR) {
    //console.log(action.payload);
    return Object.assign({}, state, {
      signupAlert: action.payload
    });
  }
  if (action.type === LOGIN_SUCCESS) {
    return Object.assign({}, state, {
      user: { loggedIn: true, user: action.payload },
      loginAlert: []
    });
  }
  if (action.type === LOGIN_ERROR) {
    return Object.assign({}, state, {
      loginAlert: action.payload
    });
  }
  if (action.type === AUTH_SUCCESS) {
    return Object.assign({}, state, {
      user: action.payload
    });
  }
  if (action.type === AUTH_ERROR) {
    return Object.assign({}, state, {
      user: action.payload
    });
  }
  if (action.type === LOGOUT) {
    return Object.assign({}, state, {
      user: action.payload
    });
  }
  if (action.type === CATEGORIES_LOADING) {
    return Object.assign({}, state, {
      categoriesLoading: true,
      categoriesError: '',
    });
  }
  if (action.type === CATEGORIES_SUCCESS) {
    return Object.assign({}, state, {
      categoriesLoading: false,
      categoriesError: '',
      categories: action.payload.categories
    });
  }
  if (action.type === CATEGORIES_ERROR) {
    return Object.assign({}, state, {
      categoriesLoading: false,
      categoriesError: action.error,
    });
  }
  if (action.type === CODES_LOADED) {
    console.log('Promocode response: ', action.payload.data);
    return Object.assign({}, state, {
      price: Number(action.payload.data.orderPrice),
      deliveryPrice: action.payload.data.deliveryPrice,
      wrapPrice: action.payload.data.wrapPrice,
      finalPrice: action.payload.data.orderPrice + action.payload.data.deliveryPrice + action.payload.data.wrapPrice,
      usedCode: action.payload.usedCode,
      promoType: action.payload.data.promoType,
      codeError: ''
    });
  }
  if (action.type === CODES_LOADED_ERR) {
    return Object.assign({}, state, {
      codeError: action.payload.message
    });
  }
  if (action.type === COPY_ORDERS) {
    return Object.assign({}, state, {
      order: action.payload.order,
      price: action.payload.order.map(item => item.price * item.amount).reduce((a, b) => a + b, 0),
      wrapPrice: action.payload.wrapPrice
    });
  }
  if (action.type === MENU_ITEMS_LOADED) {
    return Object.assign({}, state, {
      menuItems: action.payload.meals
    });
  }
  if (action.type === ADD_TO_CART) {

    let exists = state.order.filter((item) => item._id === action.payload._id);

    if (exists.length < 1) {
      if (action.payload.category_id === "5edf33c11157b732f6163721" ||
        action.payload.category_id === "5edf33b81157b732f6163720" ||
        action.payload.category_id === "5f87ff28768a872e27e87e1e" ||
        action.payload.category_id === "5f87fcf1768a872e27e87e04" ||
        action.payload.category_id === "6194b0d7d9cfab330b97f626" ||
        action.payload.category_id === "6194b0e1d9cfab330b97f627") {
        return Object.assign({}, state, {
          order: state.order.concat(action.payload),
          price: state.price + action.payload.price,
        });
      }
      else {
        return Object.assign({}, state, {
          order: state.order.concat(action.payload),
          price: state.price + action.payload.price,
          wrapPrice: state.wrapPrice + 1
        });
      }
    }
    else {
      state.order.find(item => item._id === action.payload._id).amount++;
      //console.log("AAAAAAAAAAAAAA ", state.order.find(item => item._id === action.payload._id).option);
      //state.order.find(item => item._id === action.payload._id).option.concat(action.payload.option);

      if (action.payload.category_id === "5edf33c11157b732f6163721" ||
        action.payload.category_id === "5edf33b81157b732f6163720" ||
        action.payload.category_id === "5f87ff28768a872e27e87e1e" ||
        action.payload.category_id === "5f87fcf1768a872e27e87e04" ||
        action.payload.category_id === "6194b0d7d9cfab330b97f626" ||
        action.payload.category_id === "6194b0e1d9cfab330b97f627") {
        return Object.assign({}, state, {
          order: state.order.map(
            (content) => content._id === action.payload._id ? { ...content, option: content.option.concat(action.payload.option) }
              : content),
          price: state.price + action.payload.price,
        });
      }
      else {
        return Object.assign({}, state, {
          order: state.order.map(
            (content) => content._id === action.payload._id ? { ...content, option: content.option.concat(action.payload.option) }
              : content),
          price: state.price + action.payload.price,
          wrapPrice: state.wrapPrice + 1
        });
      }
    }

    //let existsAdditional = state.order.filter((item) => item.category_id === "5edf33b81157b732f6163720");
    // console.log('filter',exists.length);
    // let isOther = state.order.map(item => item !== "5edf33b81157b732f6163720")

    // console.log("Czy jest coś poza dodatkiem? ", isOther.length);

    // if(exists.length < 1 ){
    //   if(action.payload.category_id === "5edf33c11157b732f6163721"){
    //     console.log("NAPÓJ");
    //     return Object.assign({}, state, {
    //       order: state.order.concat(action.payload),
    //       price: state.price + action.payload.price
    //     });
    //   }
    //   else if (isOther.length > 0 && action.payload.category_id === "5edf33b81157b732f6163720"){
    //     console.log("jest coś innego niz dodatek");
    //     return Object.assign({}, state, {
    //       order: state.order.concat(action.payload),
    //       price: state.price + action.payload.price
    //     });
    //   }
    //   else if (isOther.length < 0 && action.payload.category_id === "5edf33b81157b732f6163720"){
    //     console.log("same dodatki");
    //     return Object.assign({}, state, {
    //       order: state.order.concat(action.payload),
    //       price: state.price + action.payload.price,
    //       wrapPrice: state.wrapPrice + 1
    //     });
    //   }
    //   else{
    //     console.log("NIE MA POZOSTAŁE");
    //     return Object.assign({}, state, {
    //       order: state.order.concat(action.payload),
    //       price: state.price + action.payload.price,
    //       wrapPrice: state.wrapPrice + 1
    //     });
    //   }
    // }
    // else{

    //   state.order.find(item => item._id === action.payload._id).amount++;
    //   if(action.payload.category_id === "5edf33b81157b732f6163720" || action.payload.category ===  "5edf33c11157b732f6163721"){
    //     console.log("JEST i DODATEK LUB NAPÓJ");
    //     return Object.assign({}, state, {
    //       order: state.order,
    //       price: state.price + action.payload.price
    //     });
    //   }
    //   else{
    //     console.log("JEST i inne");
    //     return Object.assign({}, state, {
    //       order: state.order,
    //       price: state.price + action.payload.price,
    //       wrapPrice: state.wrapPrice + 1
    //     });
    //   }

    // }
  }

  if (action.type === GET_ORDER_HISTORY_LOADING) {
    return Object.assign({}, state, {
      historyOrdersLoading: true,
      historyOrdersError: '',
    });
  }
  if (action.type === GET_ORDER_HISTORY_SUCCESS) {
    return Object.assign({}, state, {
      historyOrdersLoading: false,
      historyOrdersError: '',
      historyOrders: action.payload.data
    });
  }
  if (action.type === GET_ORDER_HISTORY_ERROR) {
    return Object.assign({}, state, {
      historyOrdersLoading: false,
      historyOrdersError: action.error,
    });
  }

  if (action.type === DELETE_FROM_CART) {
    return Object.assign({}, state, {
      order: action.payload
    });
  }
  if (action.type === PLACE_CHANGE) {
    if (action.payload === "in") {
      return Object.assign({}, state, {
        place: action.payload,
        wrapPriceActual: state.wrapPrice,
        wrapPrice: 0
      });
    }
    else if (action.payload === "out") {
      return Object.assign({}, state, {
        place: action.payload,
        wrapPrice: state.wrapPriceActual
      });
    }
  }
  if (action.type === REALIZATION_CHANGE) {
    if (action.payload === "delivery" && state.place === "in") {
      return Object.assign({}, state, {
        realization: action.payload,
        street: "",
        deliveryPrice: 0,
        postal_code: "",
        local: "",
        locality: "",
        distance: 0,
        building_number: '',
        place: "out",
        wrapPrice: state.wrapPriceActual,
        paymentType: '',
        paymentTypeName: '',
      });
    }
    else if (action.payload === "delivery" && state.place === "out") {
      return Object.assign({}, state, {
        realization: action.payload,
        street: "",
        deliveryPrice: 0,
        postal_code: "",
        local: "",
        locality: "",
        distance: 0,
        building_number: '',
        place: "out",
        wrapPrice: state.wrapPrice,
        paymentType: '',
        paymentTypeName: '',
      });
    }
    else {
      return Object.assign({}, state, {
        realization: action.payload,
        street: "",
        deliveryPrice: 0,
        postal_code: "",
        local: "",
        locality: "",
        distance: 0,
        building_number: '',
        place: "out",
        wrapPrice: state.wrapPrice,
      });
    }
  }
  if (action.type === REALIZATION_TIME_CHANGE) {
    return Object.assign({}, state, {
      realizationTime: action.payload
    });
  }
  if (action.type === CHECK_STATUS) {
    return Object.assign({}, state, {
      activeOrderId: action.payload[0],
    });
  }
  if (action.type === CHECK_STATUS_ERROR) {
    return Object.assign({}, state, {
      orderError: action.payload,
    });
  }
  if (action.type === REALIZATION_TIME_VALUE_CHANGE) {
    return Object.assign({}, state, {
      realizationTimeValue: action.payload
    });
  }
  if (action.type === PAYMENT_CHANGE) {
    return Object.assign({}, state, {
      paymentType: action.payload.type,
      paymentTypeName: action.payload.name
    });
  }
  if (action.type === PROMOPRODUCT_DELETE) {
    console.log("PROMO DELETE");
    const promoProducts = state.order.filter(item => item.promoProduct === true);
    if (promoProducts.length > 0) {
      console.log("PROMO DELETE IN", action.payload.data);
      return Object.assign({}, state, {
        order: state.order.filter((item) => item.promoProduct !== true),
        finalPrice: action.payload.data.orderPrice + action.payload.data.deliveryPrice + action.payload.data.wrapPrice,
      });
    }
    else {
      console.log("PROMO DELETE OUT", action.payload.data);
      return Object.assign({}, state, {
        finalPrice: action.payload.data.orderPrice + action.payload.data.deliveryPrice + action.payload.data.wrapPrice,
      });
    }
  }
  if (action.type === AMOUNT_ADD) {
    console.log("PAYLOAD", action.payload)
    state.order.find(item => item._id === action.payload._id).amount++;
    if (action.payload.option) {
      state.order.find(item => item._id === action.payload._id).option.push(action.payload.option);
    }

    if (action.payload.category_id === "5edf33c11157b732f6163721" ||
      action.payload.category_id === "5edf33b81157b732f6163720" ||
      action.payload.category_id === "5f87ff28768a872e27e87e1e" ||
      action.payload.category_id === "5f87fcf1768a872e27e87e04" ||
      action.payload.category_id === "6194b0d7d9cfab330b97f626" ||
      action.payload.category_id === "6194b0e1d9cfab330b97f627") {
      return Object.assign({}, state, {
        order: state.order,
        price: state.price + action.payload.price
      })
    }
    else {
      if (state.place === "out") {
        return Object.assign({}, state, {
          order: state.order,
          price: state.price + action.payload.price,
          wrapPrice: state.wrapPrice + 1
        })
      }
      else {
        return Object.assign({}, state, {
          order: state.order,
          price: state.price + action.payload.price,
          wrapPriceActual: state.wrapPriceActual + 1
        })
      }
    }
  }
  if (action.type === AMOUNT_DELETE) {
    let exists = state.order.filter((item) => item._id === action.payload._id);
    //console.log('filter', exists.amount);

    if (action.payload.amount === 1) {
      if (action.payload.category_id === "5edf33c11157b732f6163721" ||
        action.payload.category_id === "5edf33b81157b732f6163720" ||
        action.payload.category_id === "5f87ff28768a872e27e87e1e" ||
        action.payload.category_id === "5f87fcf1768a872e27e87e04" ||
        action.payload.category_id === "6194b0d7d9cfab330b97f626" ||
        action.payload.category_id === "6194b0e1d9cfab330b97f627") {
        return Object.assign({}, state, {
          order: state.order.filter((item) => item._id !== action.payload._id),
          price: state.price - action.payload.price
        });
      }
      else {
        if (state.place === "out") {
          return Object.assign({}, state, {
            order: state.order.filter((item) => item._id !== action.payload._id),
            price: state.price - action.payload.price,
            wrapPrice: state.wrapPrice - 1
          });
        }
        else {
          return Object.assign({}, state, {
            order: state.order.filter((item) => item._id !== action.payload._id),
            price: state.price - action.payload.price,
            wrapPriceActual: state.wrapPriceActual - 1
          });
        }
      }
    }
    else {
      state.order.find(item => item._id === action.payload._id).amount--;
      if (action.payload.category_id === "5edf33c11157b732f6163721" ||
        action.payload.category_id === "5edf33b81157b732f6163720" ||
        action.payload.category_id === "5f87ff28768a872e27e87e1e" ||
        action.payload.category_id === "5f87fcf1768a872e27e87e04" ||
        action.payload.category_id === "6194b0d7d9cfab330b97f626" ||
        action.payload.category_id === "6194b0e1d9cfab330b97f627") {
        return Object.assign({}, state, {
          order: state.order.map(
            (content) => content._id === action.payload._id ? { ...content, option: content.option.splice(0, content.option.length - 1) }
              : content),
          price: state.price - action.payload.price
        });
      }
      else {
        if (state.place === "out") {
          return Object.assign({}, state, {
            order: state.order.map(
              (content) => content._id === action.payload._id ? { ...content, option: content.option.splice(0, content.option.length - 1) }
                : content),
            price: state.price - action.payload.price,
            wrapPrice: state.wrapPrice - 1
          });
        }
        else {
          return Object.assign({}, state, {
            order: state.order.map(
              (content) => content._id === action.payload._id ? { ...content, option: content.option.splice(0, content.option.length - 1) }
                : content),
            price: state.price - action.payload.price,
            wrapPriceActual: state.wrapPriceActual - 1
          });
        }
      }
    }
  }
  if (action.type === DELIVERY_DATA_UPDATE) {

    return Object.assign({}, state, {
      deliveryPrice: action.payload.delivery_price,
      distance: action.payload.distance,
      building_number: action.payload.building_number,
      street: action.payload.street,
      locality: action.payload.locality,
      postal_code: action.payload.postal_code,
      vicinity: action.payload.vicinity,
    });
  }
  if (action.type === DELIVERY_FORM_UPDATE) {
    return Object.assign({}, state, {
      name: action.payload.name,
      surname: action.payload.surname,
      email: action.payload.email,
      phone: action.payload.phone,
      local: action.payload.local,
      cutlery: action.payload.cutlery,
      rodo: action.payload.rodo,
      additionalInfo: action.payload.additionalInfo
    });
  }
  if (action.type === GET_PAYMENT_METHODS) {
    return Object.assign({}, state, {
      paymentMethods: action.payload.payMethods
    });
  }
  if (action.type === PICKUP_FORM_UPDATE) {
    return Object.assign({}, state, {
      name: action.payload.name,
      surname: action.payload.surname,
      email: action.payload.email,
      phone: action.payload.phone,
      cutlery: action.payload.cutlery,
      rodo: action.payload.rodo,
      additionalInfo: action.payload.additionalInfo
    });
  }
  if (action.type === SET_ORDER_ID) {
    return Object.assign({}, state, {
      newOrderId: action.payload
    });
  }
  if (action.type === ORDER_FETCH_LOADING) {
    //console.log("fetched order", action.payload)
    return Object.assign({}, state, {
      orderLoading: true,
      orderError: '',
    });
  }
  if (action.type === ORDER_FETCH_SUCCESS) {
    //console.log("fetched order", action.payload)
    return Object.assign({}, state, {
      orderLoading: false,
      orderError: '',
      activeOrder: action.payload
    });
  }
  if (action.type === ORDER_FETCH_ERROR) {
    //console.log("fetched order", action.payload)
    return Object.assign({}, state, {
      orderLoading: false,
      orderError: action.error,
    });
  }
  if (action.type === UPDATE_ORDER) {
    //console.log("STATE ACTUALIZATION", action.payload)
    return Object.assign({}, state, {
      activeOrder: action.payload
    });
  }
  if (action.type === GET_OPEN_HOURS_LOADING) {
    return Object.assign({}, state, {
      openhoursLoading: true,
      openhoursError: '',
    });
  }
  if (action.type === GET_OPEN_HOURS_SUCCESS) {
    return Object.assign({}, state, {
      openhoursLoading: false,
      openhoursError: '',
      openhours: action.payload.openhours,
    });
  }
  if (action.type === GET_OPEN_HOURS_ERROR) {
    return Object.assign({}, state, {
      openhoursLoading: false,
      openhoursError: action.error,
    });
  }
  if (action.type === CLEAR_ACTIVE_ORDER) {
    return Object.assign({}, state, {
      activeOrder: [],
      activeOrderId: null
    });
  }
  if (action.type === GET_MEALS_OPTIONS) {
    return Object.assign({}, state, {
      mealsOptions: action.payload.mealsOptions
    });
  }
  if (action.type === GET_MEALS_OPTIONS_CATEGORIES) {
    return Object.assign({}, state, {
      mealsOptionsCategories: action.payload.mealsOptionsCategories
    });
  }
  // Localization reducers
  if (action.type === GET_LOCALIZATIONS_LOADING) {
    return Object.assign({}, state, {
      localizationsLoading: true,
      localizationsError: '',
    });
  }
  if (action.type === GET_LOCALIZATIONS_SUCCESS) {
    return Object.assign({}, state, {
      localizationsLoading: false,
      localizationsError: '',
      localizations: action.payload.localizations,
      activeLocalization: !localStorage.getItem('activeLocal') ? action.payload.localizations[2]._id : JSON.parse(localStorage.getItem('activeLocal')),
    });
  }
  if (action.type === GET_LOCALIZATIONS_ERROR) {
    return Object.assign({}, state, {
      localizationsLoading: false,
      localizationsError: action.error,
    });
  }
  if (action.type === CHANGE_LOCALIZATION) {
    return Object.assign({}, state, {
      activeLocalization: action.payload.id,
      building_number: "",
      street: "",
      locality: "",
      postal_code: "",
      local: "",
      order: [],
      price: 0,
      deliveryPrice: 0,
      finalPrice: 0,
      wrapPrice: 0,
      distance: 0,
      place: "out",
      paymentType: '',
      paymentTypeName: '',
      usedCode: ''
    });
  }
  if (action.type === GET_INFOS_LOADING) {
    return Object.assign({}, state, {
      infosLoading: true,
      infosError: '',
    });
  }
  if (action.type === GET_INFOS_SUCCESS) {
    return Object.assign({}, state, {
      infosLoading: false,
      infosError: '',
      infos: action.payload.infos,
    });
  }
  if (action.type === GET_INFOS_ERROR) {
    return Object.assign({}, state, {
      infosLoading: false,
      infosError: action.error,
    });
  }
  return state;
}

export default rootReducer;
