import React from 'react';

const MoneyIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="384.655" height="269.254" viewBox="0 0 384.655 269.254">
  <path className={`${classNamePath || ""}`} id="money-bill-wave" d="M373.325,45.5C350.012,35.72,326.681,32,303.361,32c-74.027-.006-148.048,37.467-222.074,37.467A182.352,182.352,0,0,1,25.6,61.221a20.55,20.55,0,0,0-6.22-.974A19.125,19.125,0,0,0,0,79.366V270.043c0,7.591,4.345,14.785,11.323,17.706,23.313,9.785,46.645,13.5,69.964,13.5,74.027,0,148.054-37.473,222.08-37.473a182.353,182.353,0,0,1,55.684,8.246,20.549,20.549,0,0,0,6.22.974,19.125,19.125,0,0,0,19.383-19.118V63.211c-.006-7.6-4.351-14.785-11.329-17.712ZM28.849,92.234a209.153,209.153,0,0,0,37.7,5.367,38.51,38.51,0,0,1-37.7,30.976Zm0,171.289V234.806a38.452,38.452,0,0,1,38.291,36.9A145.192,145.192,0,0,1,28.849,263.522Zm163.476-39.2c-26.559,0-48.081-25.838-48.081-57.7s21.528-57.7,48.081-57.7,48.081,25.832,48.081,57.7S218.871,224.324,192.324,224.324ZM355.8,241.02a206.642,206.642,0,0,0-32.647-5.073A38.384,38.384,0,0,1,355.8,206.33Zm0-141.905a38.388,38.388,0,0,1-33.543-36.992A144.473,144.473,0,0,1,355.8,69.732Z" transform="translate(0 -32)"/>
</svg>
);

export default MoneyIcon;