import React from "react";
import validateInput from './Validations/signup';
import { connect } from "react-redux";
import { addUser } from "../../js/actions/index";
import { Link } from 'react-router-dom';
import Alert from "../Alerts/Alert";
import isEmpty from 'lodash/isEmpty';

function mapDispatchToProps(dispatch) {
  return {
    addUser: article => dispatch(addUser(article))
  };
}

const mapStateToProps = state => {
  return {
    signupAlert: state.signupAlert
  }
}

class ConnectedForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      phone: '',
      email: '',
      rodo: false,
      password: '',
      errors: {},
      alerts: {},
      isLoading: false,
      successMessage: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.first = this.first.bind(this);
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  first(v) {
    return v[Object.keys(v)[0]];
  };

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value.trim() });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true });
      this.props.addUser(this.state).then(
        (res) => this.setState({ name: "", surname: "", rodo: false, phone: "", email: "", password: "", alerts: this.props.signupAlert }),
        (err) => this.setState({ errors: err.data.errors, isLoading: false })
      )
    }
  }

  render() {
    const { name, surname, rodo, phone, email, password, errors } = this.state;
    return (
      <div>
        {console.log('ALERTY: ', this.state.alerts)}
        {(isEmpty(this.state.alerts) || !isEmpty(this.state.errors)) ? '' : <Alert alertMessage={this.state.alerts.message} type={this.state.alerts.error === true ? 'error' : 'success'} />}
        {isEmpty(this.state.errors) ? '' : <Alert alertMessage={this.first(this.state.errors)} type="error" />}
        <form onSubmit={this.handleSubmit}>
          <div className="mt-1">
            <div className="form-group">
              <label htmlFor="name">Imię</label>
              <input
                className="form-control"
                type="text"
                id="name"
                value={name}
                onChange={this.handleChange}
                error={errors.name}
              />
            </div>
            <div className="form-group">
              <label htmlFor="surname">Nazwisko</label>
              <input
                className="form-control"
                type="text"
                id="surname"
                value={surname}
                onChange={this.handleChange}
                error={errors.surname}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Numer Telefonu</label>
              <input
                className="form-control"
                type="number"
                id="phone"
                value={phone}
                onChange={this.handleChange}
                error={errors.phone}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                className="form-control"
                type="text"
                id="email"
                value={email}
                onChange={this.handleChange}
                error={errors.email}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Hasło</label>
              <input
                className="form-control"
                type="password"
                id="password"
                value={password}
                onChange={this.handleChange}
                error={errors.password}
              />
              {/* <p className="form-error"> {errors.password ? errors.password : ''}</p> */}
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                id="rodo"
                checked={rodo}
                onChange={this.handleChange}
                error={errors.rodo}
              />
              <label htmlFor="rodo">Zapoznałam(em) się i akceptuję <Link to="/regulamin">Regulamin</Link> i <Link to="/polityka-prywatnosci">Politykę Prywatności</Link>.</label>
            </div>
          </div>
          <button type="submit">Zarejestruj się</button>
        </form>
        <div className="shortcut">
          <p>Masz już konto? <Link to="/login" className="">Zaloguj się</Link></p>
        </div>
      </div>
    );
  }
}


const SignupForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedForm);

export default SignupForm;