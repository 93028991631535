import React from 'react';

const ArrowIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="269.254" height="307.719" viewBox="0 0 30 30">
  <path className={`${classNamePath || ""}`} id="arrow" d="M18.629 15.997l-7.083-7.081L13.462 7l8.997 8.997L13.457 25l-1.916-1.916z"/>
</svg>);

export default ArrowIcon;