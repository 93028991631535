import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateStatusData(data){
    let errors = {};

    if(Validator.isEmpty(data.phone)) {
        errors.phone = 'Podaj numer telefonu!';
    }

    if(!Validator.isNumeric(data.phone) && !Validator.isEmpty(data.phone)) {
        errors.phone = 'Podaj prawidłowy numer!';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
}