import React from 'react';
import './Cart.css';
import CartIcon from '../Icons/Cart';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { amountAdd, amountDelete, refreshCode } from '../../js/actions/index';
import Alert from '../Alerts/Alert';

import ToggleButton from './ToggleButton';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      isEmpty: this.props.order.length === 0 ? 'none' : 'flex',
      setActive: '',
      setHeight: '0px',
      local: '',
      showOptions: false,
      showError: false,
      option: "",
      activeItem: {},
    };

    this.content = React.createRef();

    this.toggleCart = this.toggleCart.bind(this);
    this.handleCloseOptions = this.handleCloseOptions.bind(this);
    this.handleShowOptions = this.handleShowOptions.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleHideError = this.handleHideError.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.handleAmountAdd = this.handleAmountAdd.bind(this);
  }

  handleCloseOptions() { this.setState({ showOptions: false, activeItem: {}, option: "" }) };
  handleShowOptions(item) { this.setState({ showOptions: true, activeItem: item }) };

  handleError() { this.setState({ showError: true }); };
  handleHideError() { this.setState({ showError: false }) };

  handleAmountAdd(item, option) {
    this.props.amountAdd(item, option);
    this.handleCloseOptions();
  }

  onOptionChange(event) {
    this.setState({
      option: event.target.value
    });

    this.handleHideError();
  }

  componentDidMount() {
    this.setState({ local: this.props.activeLocalization });
  }

  toggleCart() {
    this.setState({ setActive: (this.state.setActive === "" ? "activex" : "") });
    this.setState({ setHeight: (this.state.setActive === "activex" ? "0px" : `${this.content.current.scrollHeight + 40}px`) });
  }


  componentDidUpdate(prevProps) {
    if (prevProps.price !== this.props.price) {
      let orderPrice = Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0));
      let deliveryPrice = Number(this.props.deliveryPrice);
      let wrapPrice = Number(this.props.wrapPrice);

      this.props.refreshCode(this.props.usedCode, orderPrice, deliveryPrice, wrapPrice, this.props.order, this.state.local);
    }
  }
  render() {
    return (
      <div className={`cart`} style={{ display: this.props.order.length === 0 ? 'none' : 'flex' }}>

        <div className="cart__container">
          <div ref={this.content} className={`cart__big ${this.state.setActive}`} style={{ maxHeight: `${this.state.setHeight}` }}>
            <div className="cart__big__content">
              <h2> Twoje zamówienie</h2>
              <div className="cart__products">
                <div className="cart__single__product">
                  {this.props.order.filter(item => !item.promoProduct).map(item => (
                    <div key={item._id} className="cart__row">
                      <span className="cart__product__amount">{item.amount}x</span>
                      <span className="cart__product__name">{item.order} {!item.option ? null : item.option.length > 0 ? <span>({item.option.join(", ")})</span> : null}</span>
                      <div className="cart__product__edit_amount">
                        <button className="change__amount" onClick={() => { this.props.amountDelete(item) }}>-</button>
                        {
                          this.props.mealsOptionsCategories.filter(meal => item._id === meal.meal_id).length === 0
                            ?
                            <button className="change__amount" onClick={() => { this.props.amountAdd(item) }}>+</button>
                            :
                            <button className="change__amount" onClick={() => { this.handleShowOptions(item) }}>+</button>
                        }
                      </div>
                      <span className="cart__product__price">{Number(item.price * item.amount).toFixed(2)} zł</span>
                      {/* <button className="cart__product__delete">  </button> */}

                    </div>
                  ))}
                  {this.props.order.filter(item => item.promoProduct).map(item => (
                    <div key={item._id} className="cart__row promo_product__cart">
                      <span className="cart__product__amount">{item.amount}x</span>
                      <span className="cart__product__name">{item.order} {!item.option ? null : item.option.length > 0 ? <span>({item.option.join(", ")})</span> : null}</span>

                      <span className="cart__product__price">{Number(item.price * item.amount).toFixed(2)} zł</span>

                    </div>
                  ))}
                </div>
              </div>
              <div className="cart_summary">
                <div className="cart__row">
                  <span className="cart__summary__name"> Koszt zamówienia: </span>
                  <span className="cart__summary__price"> {Number(this.props.price).toFixed(2)} ZŁ </span>
                </div>
                <div className="cart__row">
                  <span className="cart__summary__name"> Koszt dostawy: </span>
                  <span className="cart__summary__price"> {Number(this.props.realization === "pick-up" ? 0 : this.props.deliveryPrice).toFixed(2)} ZŁ </span>
                </div>
                <div className="cart__row">
                  <span className="cart__summary__name"> Opakowanie: </span>
                  <span className="cart__summary__price"> {Number(this.props.wrapPrice).toFixed(2)} ZŁ </span>
                </div>
                <div className="cart__row">
                  <span className="cart__summary__name"> Kwota całkowita: </span>
                  {this.props.usedCode.length === 0 ?
                    <span className="cart__summary__price">
                      {Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0) + Number(this.props.deliveryPrice) + Number(this.props.wrapPrice)).toFixed(2)} ZŁ
                    </span>
                    :
                    <span className="cart__summary__price">
                      <s> {Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0) + Number(this.props.deliveryPrice) + Number(this.props.wrapPrice)).toFixed(2)} ZŁ </s>
                      {Number(this.props.finalPrice).toFixed(2)} ZŁ
                    </span>
                  }
                </div>
              </div>
              {
                this.props.localizations.filter(
                  item => item._id === this.props.activeLocalization).map(item => (
                    item.deliveryEnabled ? <ToggleButton /> : null
                  )
                  )
              }
              <Link to="/checkout">
                <button className="button__cart">
                  Przejdź dalej
                </button>
              </Link>
            </div>
          </div>
          <div className="cart__small" onClick={this.toggleCart}>
            <div className="cart__icon__box"><CartIcon className="cart__icon" /></div>
            <div className="cart__content">
              <div className="cart__title">Twoje zamówienie</div>
            </div>
            <div className="cart__price">
              {this.props.usedCode.length === 0 ?
                <div className="cart__money">
                  {Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0) + Number(this.props.deliveryPrice) + Number(this.props.wrapPrice)).toFixed(2)}
                </div>
                :
                <div className="cart__money">
                  {Number(this.props.finalPrice).toFixed(2)}
                </div>
              }
              <div className="cart__currency">PLN</div>
            </div>
          </div>
        </div>
        <div className="modal" style={{ display: this.state.showOptions === true ? 'block' : 'none' }}>
          <div className="modal-main">
            <div>
              <h2>Wybierz Sos</h2>
            </div>
            <div onChange={this.onOptionChange} style={{ display: "flex", flexDirection: "column" }}>
              {this.props.mealsOptionsCategories.filter(meal => this.state.activeItem._id === meal.meal_id).map(category =>
                this.props.mealsOptions.filter(options => options.category_id === category._id).map(option =>
                  <label key={option._id} style={{ padding: "5px 10px" }}>
                    <input
                      type="radio"
                      name={category._id}
                      value={option.name}
                      className="form-check-input"
                    />
                    <span style={{ padding: "0px 10px" }}>{option.name}</span>
                  </label>
                )
              )}
            </div>
            {this.state.showError === false ? '' : <Alert alertMessage={"Wybierz sos"} type="error" />}
            <button onClick={() => { this.state.option.length < 1 ? this.handleError() : this.handleAmountAdd(this.state.activeItem, this.state.option) }}>
              Dodaj do koszyka
            </button>
          </div>
        </div>
      </div>
    )
  }

}

function mapStateToProps(state) {
  return {
    mealsOptionsCategories: state.mealsOptionsCategories,
    mealsOptions: state.mealsOptions,
    usedCode: state.usedCode,
    price: state.price,
    order: state.order,
    distance: state.distance,
    wrapPrice: state.wrapPrice,
    deliveryPrice: state.deliveryPrice,
    realization: state.realization,
    place: state.place,
    finalPrice: state.finalPrice,
    localizations: state.localizations,
    activeLocalization: state.activeLocalization
  };
}

function mapDispatchToProps(dispatch) {
  return {
    amountAdd: (item, option) => dispatch(amountAdd(item, option)),
    amountDelete: item => dispatch(amountDelete(item)),
    refreshCode: (code, orderPrice, deliveryPrice, wrapPrice, order, local) => dispatch(refreshCode(code, orderPrice, deliveryPrice, wrapPrice, order, local))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
