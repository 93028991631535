import React from 'react';
import './Alert.css';
import InfoIcon from '../Icons/Info';

class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render(){
        let className = "alert alert__" + this.props.type;
        return(
            <div className="alert__box">
                <div className={className}>
                    <InfoIcon className="alert__icon" classNamePath="alert__icon_path"/> {this.props.alertMessage}
                </div>
            </div>
        )
    }
}

export default Alert;