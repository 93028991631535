import React from 'react';
import { connect } from 'react-redux';
import { deliveryFormUpdate } from '../../../js/actions/index';
import AddressCalculate from './AddressCalculate';
import UserIcon from '../../Icons/User';
import PhoneIcon from '../../Icons/Phone';
import BuildingIcon from '../../Icons/Building';
import MailIcon from '../../Icons/Mail';
import InfoIcon from '../../Icons/Info';
import { Link } from 'react-router-dom';


class DeliveryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      phone: '',
      email: '',
      local: '',
      rodo: false,
      cutlery: false,
      additionalInfo: '',

      errors: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleCheckClick = this.handleCheckClick.bind(this);
    this.handleCheckCutlery = this.handleCheckCutlery.bind(this);
  }

  handleCheckClick() {
    this.setState({ rodo: !this.state.rodo }, () => { this.props.deliveryFormUpdate(this.state) });
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value.trim() });
  }

  handleCheckCutlery() {
    this.setState({ cutlery: !this.state.cutlery }, () => { this.props.deliveryFormUpdate(this.state) });
  }

  handleUpdate(event) {
    this.setState({ [event.target.id]: event.target.value.trim() });
    this.props.deliveryFormUpdate(this.state);
  }

  render() {
    const { name, surname, phone, rodo, email, local, additionalInfo, cutlery, errors } = this.state;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div>
            <div className="form__group__checkout">
              <input
                placeholder="Imię"
                className="form__control__checkout"
                type="text"
                id="name"
                value={name}
                onChange={this.handleChange}
                error={errors.name}
                onBlur={this.handleUpdate}
              />
              <div className="cart__icon__box">
                <UserIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>

              <p className="form-error"> {errors.name ? errors.name : ''}</p>
            </div>
            <div className="form__group__checkout">
              <input
                placeholder="Nazwisko"
                className="form__control__checkout"
                type="text"
                id="surname"
                value={surname}
                onChange={this.handleChange}
                error={errors.surname}
                onBlur={this.handleUpdate}
              />
              <div className="cart__icon__box">
                <UserIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>

              <p className="form-error"> {errors.surname ? errors.surname : ''}</p>
            </div>
            <div className="form__group__checkout">
              <input
                placeholder="Email"
                className="form__control__checkout"
                type="text"
                id="email"
                value={email}
                onChange={this.handleChange}
                error={errors.email}
                onBlur={this.handleUpdate}
              />
              <div className="cart__icon__box">
                <MailIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>

              <p className="form-error"> {errors.email ? errors.email : ''}</p>
            </div>
            <div className="form__group__checkout">
              <input
                placeholder="Telefon"
                className="form__control__checkout"
                type="tel"
                id="phone"
                value={phone}
                onChange={this.handleChange}
                error={errors.phone}
                onBlur={this.handleUpdate}

              />
              <div className="cart__icon__box">
                <PhoneIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>

              <p className="form-error"> {errors.phone ? errors.phone : ''}</p>
            </div>
            <AddressCalculate />
            <div className="form__group__checkout">
              <input
                placeholder="Numer Mieszkania"
                className="form__control__checkout"
                type="text"
                id="local"
                value={local}
                onChange={this.handleChange}
                error={errors.local}
                onBlur={this.handleUpdate}
              />
              <div className="cart__icon__box">
                <BuildingIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>

              <p className="form-error"> {errors.local ? errors.local : ''}</p>
            </div>
            <div className="form__group__checkout">
              <input
                placeholder="Dodatkowe informacje"
                className="form__control__checkout"
                type="text"
                id="additionalInfo"
                value={additionalInfo}
                onChange={this.handleChange}
                error={errors.additionalInfo}
                onBlur={this.handleUpdate}
              />
              <div className="cart__icon__box">
                <InfoIcon className="cart__icon" classNamePath="choose__icon__path" />
              </div>

              <p className="form-error"> {errors.additionalInfo ? errors.additionalInfo : ''}</p>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                id="cutlery"
                checked={cutlery}
                onChange={this.handleCheckCutlery}
              />
              <label htmlFor="cutlery" style={{ paddingLeft: '10px' }}>Zapakować sztućce, sól i serwetki?</label>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                id="rodo"
                checked={rodo}
                error={errors.rodo}
                onChange={this.handleCheckClick}
              />
              <label htmlFor="rodo">Zapoznałam(em) się i akceptuję <Link to="/regulamin">Regulamin</Link> i <Link to="/polityka-prywatnosci">Politykę Prywatności</Link>.</label>
            </div>
          </div>
        </form>
      </div>
    )
  }

}

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {
    deliveryFormUpdate: data => dispatch(deliveryFormUpdate(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryForm);
