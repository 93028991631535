import React from 'react';
import { connect } from 'react-redux';
import DeliveryIcon from '../../Icons/Delivery';
import PickupIcon from '../../Icons/Pickup';
import DeliveryForm from './DeliveryForm';
import PickupForm from './PickupForm';
import './Checkout.css';
import { realization, refreshCode } from '../../../js/actions';

function mapDispatchToProps(dispatch) {
  return {
    realization: type => dispatch(realization(type)),
    refreshCode: (code, orderPrice, deliveryPrice, wrapPrice, order, local) => dispatch(refreshCode(code, orderPrice, deliveryPrice, wrapPrice, order, local))
  }
}

class Delivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      realization: '',
      local: ''
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.setState({ local: this.props.activeLocalization });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.realizationx !== this.props.realizationx) {
      let orderPrice = Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0));
      let deliveryPrice = Number(this.props.deliveryPrice);
      let wrapPrice = Number(this.props.wrapPrice);

      console.log('Delivery: ', this.props.code, orderPrice, deliveryPrice, wrapPrice, this.props.order, this.state.local);
      this.props.refreshCode(this.props.code, orderPrice, deliveryPrice, wrapPrice, this.props.order, this.state.local);
    }
  }

  handleClick(type) {
    if (type !== this.state.realization) {
      this.setState({ realization: type });
      this.props.realization(type);
    }
    else {

    }
  }

  render() {

    let activeForm;

    if (this.props.realizationx === "delivery") {
      activeForm = <DeliveryForm />;
    } else if (this.props.realizationx === "pick-up") {
      activeForm = <PickupForm />;
    }

    return (
      <div>
        <h2>Sposób realizacji</h2>
        <div className="choose__container">
          <div className={this.props.realizationx === "pick-up" ? "choose__button checked" : "choose__button"} onClick={() => this.handleClick("pick-up")}>
            <PickupIcon className="choose__icon" classNamePath="choose__icon__path" />
            Odbiór
          </div>
          {this.props.localizations.filter(item => item._id === this.props.activeLocalization).map(item => (
            item.deliveryEnabled
              ?
              <div key={item._id} className={this.props.realizationx === "delivery" ? "choose__button checked" : "choose__button"} onClick={() => this.handleClick("delivery")}>
                <DeliveryIcon className="choose__icon" classNamePath="choose__icon__path" />
                Dowóz
              </div>
              :
              null

          ))}
        </div>
        <div className="form__container realization">
          {activeForm}
        </div>
      </div>
    )
  }

}

function mapStateToProps(state) {
  return {
    realizationx: state.realization,
    code: state.usedCode,
    deliveryPrice: state.deliveryPrice,
    price: state.price,
    wrapPrice: state.wrapPrice,
    order: state.order,
    localizations: state.localizations,
    activeLocalization: state.activeLocalization
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);
