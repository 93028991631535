import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { connect } from "react-redux";
import axios from 'axios';
import { deliveryDataUpdate, refreshCode, getLocalizations } from '../../../js/actions/index';
import DeliveryIcon from '../../Icons/Delivery';
import 'react-google-places-autocomplete/dist/assets/index.css';

class AddressCalculate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      distance: 0,
      delivery_price: 0,

      building_number: '',
      street: '',
      locality: '',
      postal_code: '',
      apartment: '',
      vicinity: '',
      local: ''
    };
    this.handleDistance = this.handleDistance.bind(this);
    this.calculateDeliveryPrice = this.calculateDeliveryPrice.bind(this);
  }

  calculateDeliveryPrice() {
    if (String(this.props.localizations.filter(item => item._id === this.props.activeLocalization).map(item => item.deliveryZonesType)) === "multiple") {

      let zakres = Number(this.props.localizations.filter(item => item._id === this.props.activeLocalization)
        .map(item => item.zones.filter(item => item.from < this.state.distance && item.to > this.state.distance).map(item => item.price))).toFixed(2);

      //console.log("ZAKRES", zakres, typeof(zakres));

      if (zakres === null) {
        this.setState({ delivery_price: 0 });
        //console.log("zakres nothing");
      }
      else {
        this.setState({ delivery_price: zakres });
        //console.log("zakres: ", zakres);
      }
    }
    else {
      if (String(this.props.localizations.filter(item => item._id === this.props.activeLocalization).map(item => item.vicinity)) === this.state.vicinity) {
        this.setState({ delivery_price: 11.00 });
      }
      else {
        this.setState({ delivery_price: 0 });
      }
    }
  }

  handleDistance(id) {
    let url = `${process.env.REACT_APP_API}distance`;
    let url2 = `${process.env.REACT_APP_API}addresscheck`;

    // let orderPrice = Number(this.props.order.map(item=> item.amount*item.price).reduce((pv, cv) => pv + cv, 0));
    // let deliveryPrice = Number(this.props.deliveryPrice);
    // let wrapPrice = Number(this.props.wrapPrice);

    this.setState({ address: id });
    //console.log('address',id, this.props.localizations.filter(item => item._id === this.props.activeLocalization).map(item => item.deliveryZonesType));

    let start = this.props.localizations.filter(item => item._id === this.props.activeLocalization).map(item => item.placeId);
    //console.log("start: ", start);

    geocodeByPlaceId(id)
      .then(results =>
        this.setState({
          building_number: results[0].address_components[0].long_name,
          street: results[0].address_components[1].long_name,
          locality: results[0].address_components[2].long_name,
          postal_code: results[0].address_components[6].long_name,
          apartment: "",
        })
      )
      .catch(error => console.error(error));

    if (String(this.props.localizations.filter(item => item._id === this.props.activeLocalization).map(item => item.deliveryZonesType)) === "multiple") {
      return axios.post(url,
        {
          origin: start,
          address: id
        })
        .then((response) => {
          this.setState({ distance: response.data.distance });
          this.calculateDeliveryPrice();
          this.props.deliveryDataUpdate(this.state);
          return this;
        })
        .then(res => {
          //console.log('ADRESS RESPONSE: ', res.props.deliveryPrice)
          let orderPrice = Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0));
          let deliveryPrice = Number(this.props.deliveryPrice);
          let wrapPrice = Number(this.props.wrapPrice);

          this.props.refreshCode(this.props.code, orderPrice, deliveryPrice, wrapPrice, this.props.order, this.state.local);
        })
        .catch((err) => console.log(err));
    }
    else {
      return axios.post(url2,
        {
          origin: start,
          address: id
        })
        .then((response) => {
          this.setState({ distance: 0, vicinity: response.data.data.result.vicinity });
          //console.log(response.data.data.result, this.state.vicinity);
          this.calculateDeliveryPrice();
          this.props.deliveryDataUpdate(this.state);

          let orderPrice = Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0));
          let deliveryPrice = Number(this.props.deliveryPrice);
          let wrapPrice = Number(this.props.wrapPrice);

          this.props.refreshCode(this.props.code, orderPrice, deliveryPrice, wrapPrice, this.props.order, this.state.local);
        })
        .catch((err) => console.log(err));
    }
  }

  componentDidMount() {
    this.props.getLocalizations();
    this.setState({ local: this.props.activeLocalization });
  }

  render() {
    return (
      <div>
        <div className="destination__container">

          <GooglePlacesAutocomplete
            initialValue={this.props.street ? this.props.street.concat(", ", this.props.building_number, ", ", this.props.locality) : ""}
            placeholder={'Adres dostawy'}
            inputClassName={'destination'}
            onSelect={({ place_id }) => (
              this.handleDistance(place_id)
            )}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['pl'],
              }
            }}
          />
          <div className="cart__icon__box"><DeliveryIcon className="cart__icon" classNamePath="choose__icon__path" /></div>
        </div>
      </div>
    )
  }

}

function mapDispatchToProps(dispatch) {
  return {
    deliveryDataUpdate: data => dispatch(deliveryDataUpdate(data)),
    refreshCode: (code, orderPrice, deliveryPrice, wrapPrice, order, local) => dispatch(refreshCode(code, orderPrice, deliveryPrice, wrapPrice, order, local)),
    getLocalizations: () => dispatch(getLocalizations()),
  };
}

function mapStateToProps(state) {
  return {
    building_number: state.building_number,
    street: state.street,
    locality: state.locality,
    code: state.usedCode,
    deliveryPrice: state.deliveryPrice,
    price: state.price,
    wrapPrice: state.wrapPrice,
    order: state.order,
    localizations: state.localizations,
    activeLocalization: state.activeLocalization
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressCalculate);
