import React from 'react';
import { connect } from "react-redux";
import './OrderStatus.css';
import DeliveryIcon from '../Icons/Delivery';
import CheckIcon from '../Icons/Check';
import PickupIcon from '../Icons/Pickup';
import ChefIcon from '../Icons/Chef';

class RealizationStatusCash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      etap: 0,
      message: ''
    };
    this.realizationEtap = this.realizationEtap.bind(this);
  }

  componentDidMount() {
    this.realizationEtap()
  }

  componentDidUpdate(prevProps) {
    if (this.props.order.status !== prevProps.order.status) {
      this.realizationEtap()
    }
  }

  realizationEtap() {
    if (this.props.order.status === "Przyjęte") {
      this.setState({ etap: 1, message: 'Twoje zamówienie zostało przyjęte do realizacji' })
    }
    else if (this.props.order.status === "Przygotowanie") {
      this.setState({ etap: 2, message: 'Twoje zamówienie jest przygotowywane' })
    }
    else if (this.props.order.status === "Gotowe do odbioru") {
      this.setState({ etap: 3, message: 'Twoje zamówienie jest gotowe!' })
    }
    else if (this.props.order.status === "Zrealizowane") {
      this.setState({ etap: 4, message: 'Twoje zamówienie zostało dostarczone. Smacznego!' })
    }
  }

  render() {
    return (
      <div>
        {this.props.order.status === "Zaplanowane" ? null :
          <div className="orderStatus__container">
            <div className="orderStatus__item">
              <CheckIcon className={this.state.etap >= 1 ? "orderStatus__icon done" : "orderStatus__icon"} classNamePath="orderStatus__icon_path" />
            </div>
            <div className={this.state.etap >= 1 ? "orderStatus__item dotted_green" : "orderStatus__item dotted"}>
              <ChefIcon className={this.state.etap >= 2 ? "orderStatus__icon done" : "orderStatus__icon"} classNamePath="orderStatus__icon_path" />
            </div>
            <div className={this.state.etap >= 1 ? "orderStatus__item dotted_green" : "orderStatus__item dotted"}>
              <DeliveryIcon className={this.state.etap >= 3 ? "orderStatus__icon done" : "orderStatus__icon"} classNamePath="orderStatus__icon_path" />
            </div>
            <div className={this.state.etap >= 2 ? "orderStatus__item dotted_green" : "orderStatus__item dotted"}>
              <PickupIcon className={this.state.etap >= 4 ? "orderStatus__icon done" : "orderStatus__icon"} classNamePath="orderStatus__icon_path" />
            </div>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    order: state.activeOrder,
  };
}

export default connect(mapStateToProps)(RealizationStatusCash);