import React from 'react';
import '../Cart.css';
import { connect } from 'react-redux';
import { amountAdd, amountDelete, refreshCode } from '../../../js/actions/index';
import Alert from '../../Alerts/Alert';

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      local: '',
      showOptions: false,
      showError: false,
      option: "",
      activeItem: {},
    };
    this.handleCloseOptions = this.handleCloseOptions.bind(this);
    this.handleShowOptions = this.handleShowOptions.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleHideError = this.handleHideError.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.handleAmountAdd = this.handleAmountAdd.bind(this);
  }

  handleCloseOptions() { this.setState({ showOptions: false, activeItem: {}, option: "" }, () => console.log("STATE: ", this.state)); };
  handleShowOptions(item) { this.setState({ showOptions: true, activeItem: item }) };

  handleError() { this.setState({ showError: true }); };
  handleHideError() { this.setState({ showError: false }) };

  handleAmountAdd(item, option) {
    console.log("OPTION:", option)
    this.props.amountAdd(item, option);
    this.handleCloseOptions();
  }

  onOptionChange(event) {
    console.log(event.target.value);
    this.setState({
      option: event.target.value
    });

    this.handleHideError();
  }
  componentDidMount() {
    this.setState({ local: this.props.activeLocalization });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.price !== this.props.price) {
      //console.log("UPDATED");
      let orderPrice = Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0));
      let deliveryPrice = Number(this.props.deliveryPrice);
      let wrapPrice = Number(this.props.wrapPrice);

      this.props.refreshCode(this.props.code, orderPrice, deliveryPrice, wrapPrice, this.props.order, this.state.local);
    }
  }


  render() {

    return (
      <div>
        <div className="cart__products_checkout">
          <div className="cart__single__product">
            {/* {console.log('order', this.props.order)} */}
            {this.props.order.filter(item => !item.promoProduct).map(item => (
              <div key={item._id} className="cart__row">
                <div className="accordion__photo_min"><img src={process.env.REACT_APP_API + item.image} alt="" className="items__image" /></div>
                <span className="cart__product__name">{item.order} {item.option.length > 0 ? <span>({item.option.join(", ")})</span> : null}</span>
                <span className="cart__product__amount">x{item.amount}</span>
                <div className="cart__product__edit_amount">
                  <button className="change__amount" onClick={() => { this.props.amountDelete(item) }}>-</button>
                  {
                    this.props.mealsOptionsCategories.filter(meal => item._id === meal.meal_id).length === 0
                      ?
                      <button className="change__amount" onClick={() => { this.props.amountAdd(item) }}>+</button>
                      :
                      <button className="change__amount" onClick={() => { this.handleShowOptions(item) }}>+</button>
                  }
                </div>
                <span className="cart__product__price">{(item.price * item.amount).toFixed(2)} zł</span>
                <button className="cart__product__delete">  </button>

              </div>
            ))}
            {this.props.order.filter(item => item.promoProduct).map(item => (
              <div key={item._id} className="cart__row promo_product">
                <div className="accordion__photo_min"><img src={process.env.REACT_APP_API + item.image} alt="" className="items__image" /></div>
                <span className="cart__product__name">{item.order} {item.option.length > 0 ? <span>({item.option.join(", ")})</span> : null}</span>
                <span className="cart__product__amount">x{item.amount}</span>
                <span className="cart__product__price">{(item.price * item.amount).toFixed(2)} zł</span>
                <button className="cart__product__delete">  </button>
              </div>
            ))}
          </div>
        </div>

        <div className="modal" style={{ display: this.state.showOptions === true ? 'block' : 'none' }}>
          <div className="modal-main">
            <div>
              <h2>Wybierz Sos</h2>
            </div>
            <div onChange={this.onOptionChange} style={{ display: "flex", flexDirection: "column" }}>
              {this.props.mealsOptionsCategories.filter(meal => this.state.activeItem._id === meal.meal_id).map(category =>
                this.props.mealsOptions.filter(options => options.category_id === category._id).map(option =>
                  <label key={option._id} style={{ padding: "5px 10px" }}>
                    <input
                      type="radio"
                      name={category._id}
                      value={option.name}
                      className="form-check-input"
                    />
                    <span style={{ padding: "0px 10px" }}>{option.name}</span>
                  </label>
                )
              )}
            </div>
            {this.state.showError === false ? '' : <Alert alertMessage={"Wybierz sos"} type="error" />}
            <button onClick={() => { this.state.option.length < 1 ? this.handleError() : this.handleAmountAdd(this.state.activeItem, this.state.option) }}>
              Dodaj do koszyka
                </button>
          </div>
        </div>
      </div>
    )
  }

}

function mapStateToProps(state) {
  return {
    mealsOptionsCategories: state.mealsOptionsCategories,
    mealsOptions: state.mealsOptions,
    code: state.usedCode,
    deliveryPrice: state.deliveryPrice,
    price: state.price,
    wrapPrice: state.wrapPrice,
    order: state.order,
    activeLocalization: state.activeLocalization
  };
}

function mapDispatchToProps(dispatch) {
  return {
    amountAdd: (item, option) => dispatch(amountAdd(item, option)),
    amountDelete: item => dispatch(amountDelete(item)),
    refreshCode: (code, orderPrice, deliveryPrice, wrapPrice, order, local) => dispatch(refreshCode(code, orderPrice, deliveryPrice, wrapPrice, order, local))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);