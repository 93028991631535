import React from 'react';
import LoginForm from './LoginForm';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import './LoginSystem.css';
import { getAuth } from '../../js/actions/index';


function mapStateToProps(state) {
  return {
    user: state.user
  }
}

const mapDispatchToProps = {
  getAuth
}

class ConnectedLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user
    };
  }

  componentDidMount() {
    this.props.getAuth();

    if (this.state.user.loggedIn === true) {
      console.log('redirect')
    }
    else {
      console.log('no redirect')
    }
  }

  render() {
    const { user } = this.state;
    return this.props.user.loggedIn
      ? <Redirect to='/' />
      : <div className="main">
        {console.log(user)}
        <div className="container__small">
          <h1>Zaloguj się</h1>

          <LoginForm />
        </div>
      </div>

  }
}

const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedLoginPage);

export default LoginPage;