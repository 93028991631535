import React from 'react';
import LogInButton from './LogInButton.js';
import SocialIcon from './SocialIcon.js';
import './Header.css';
import '../../App.css';

function Header() {
  return (
    <div>
        <div className="header">
            <div className="header__content">
                <div className="header__item">
                  <SocialIcon link="https://www.facebook.com/MozeRyba" icon="facebook" color="#4267B2"/>
                  <SocialIcon link="https://www.instagram.com/moze_ryba_fish_chips/?hl=pl" icon="instagram" color="#E1306C"/>
                  <SocialIcon link="https://pl.tripadvisor.com/Restaurant_Review-g274823-d13478003-Reviews-Moze_Ryba_Fish_Chips-Bydgoszcz_Kuyavia_Pomerania_Province_Central_Poland.html" icon="tripadvisor" color="#00AF87"/>
                  <SocialIcon link="https://www.google.com/maps/place/Mo%C5%BCe+Ryba+-+Fish+%26+Chips/@53.1285451,18.0037871,17z/data=!3m1!4b1!4m5!3m4!1s0x470313bf5173f22b:0x6a0693eb3ad7a8d4!8m2!3d53.1285451!4d18.0059758" icon="google" color="#4285F4"/>
                </div>
                <div className="header__item">
                    <LogInButton />
                </div>
            </div>
        </div>
    </div>
  );
}

export default Header;