import React from 'react';

const FailIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="211.557" height="211.557" viewBox="0 0 211.557 211.557">
  <path className={`${classNamePath || ""}`} id="times" d="M145.878,185.778l60.143-60.143a18.906,18.906,0,0,0,0-26.733L192.655,85.535a18.906,18.906,0,0,0-26.733,0l-60.143,60.143L45.635,85.535a18.906,18.906,0,0,0-26.733,0L5.535,98.9a18.906,18.906,0,0,0,0,26.733l60.143,60.143L5.535,245.922a18.906,18.906,0,0,0,0,26.733L18.9,286.021a18.906,18.906,0,0,0,26.733,0l60.143-60.143,60.143,60.143a18.906,18.906,0,0,0,26.733,0l13.367-13.367a18.906,18.906,0,0,0,0-26.733Z" transform="translate(0 -80)"/>
</svg>
);

export default FailIcon;