import React from 'react';
import validatePromocode from '../Validations/promocode';
import { applyCode } from '../../../js/actions/index';
import { connect } from 'react-redux';
import './Checkout.css';
import PercentIcon from '../../Icons/Percent';
import CheckIcon from '../../Icons/Check';

function mapDispatchToProps(dispatch) {
    return {
      applyCode: (code, orderPrice, deliveryPrice, wrapPrice, order, local) => dispatch(applyCode(code, orderPrice, deliveryPrice, wrapPrice, order, local))
    };
  }

class PromoCode extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            promocode: "",
            price: '',
            error: '',
            local: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        this.setState({local: this.props.activeLocalization});
    }

    isValid(){
        const { errors, isValid } = validatePromocode(this.state);

        if(!isValid){
            this.setState({ errors });
        }

        return isValid;
      }

      handleChange(event) {
        this.setState({ [event.target.id]: event.target.value});
        console.log(this.state.promocode, this.state.price)
      }

      handleSubmit(event) {
        event.preventDefault();

        if(this.isValid() && this.props.usedCode.length === 0){
            let orderPrice = Number(this.props.order.map(item=> item.amount*item.price).reduce((pv, cv) => pv + cv, 0));
            let deliveryPrice = Number(this.props.deliveryPrice);
            let wrapPrice = Number(this.props.wrapPrice);

            console.log(orderPrice, this.state.promocode, this.props.price, this.props.deliveryPrice, this.props.order);

            this.setState({ errors: {}, isLoading: true});
            this.props.applyCode(this.state.promocode, orderPrice, deliveryPrice, wrapPrice, this.props.order, this.state.local);
        }
        else{
            this.setState({ error: "Kod został już aktywowany" });
        }
      }


    render(){
        const { promocode } = this.state;
        return(
            <div>
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <h2 htmlFor="promocode">Kod promocyjny</h2>
                        <div className="promocode__item">
                            <input
                                className="promocode__input"
                                type="text"
                                id="promocode"
                                value={promocode}
                                placeholder="KOD PROMOCYJNY"
                                onChange={this.handleChange}
                            />
                            <div className="promocode__percent">
                                <PercentIcon className="promocode__percent__icon"/>
                            </div>
                                <button type="submit" className="promocode__check">
                                    <CheckIcon className="promocode__check__icon" /> <span style={{paddingRight: '5px'}}>Aktywuj</span>
                                </button>
                                {!this.state.error ? <div> {this.props.codeError} </div> : <div> {this.state.error} </div>}
                        </div>
                    </div>
                </form>
            </div>
        )
    }

}

function mapStateToProps(state){
    return {
        price: state.price,
        deliveryPrice: state.deliveryPrice,
        wrapPrice: state.wrapPrice,
        order: state.order,
        usedCode: state.usedCode,
        codeError: state.codeError,
        activeLocalization: state.activeLocalization
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoCode);