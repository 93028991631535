import React from 'react';

const PhoneIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="307.725" height="307.725" viewBox="0 0 307.725 307.725">
  <path className={`${classNamePath || ""}`} id="phone" d="M296.536,14.781,234.03.356A14.518,14.518,0,0,0,217.5,8.71L188.654,76.024A14.388,14.388,0,0,0,192.8,92.852l36.421,29.81a222.743,222.743,0,0,1-106.5,106.5l-29.81-36.421a14.409,14.409,0,0,0-16.828-4.147L8.771,217.442A14.6,14.6,0,0,0,.356,234.03l14.424,62.505a14.42,14.42,0,0,0,14.064,11.179c153.92,0,278.87-124.71,278.87-278.87A14.407,14.407,0,0,0,296.536,14.781Z" transform="translate(0.01 0.011)"/>
</svg>);

export default PhoneIcon;