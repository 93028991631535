import React from 'react';
import socketIOClient from "socket.io-client";
import { connect } from "react-redux";
import './OrderStatus.css';
import { getOrder, updateOrder, clearActiveOrder } from '../../js/actions/index';
import RealizationMessage from './RealizationMessage';
import RealizationStatus from './RealizationStatus';
import RealizationMessageCash from './RealizationMessageCash';
import RealizationStatusCash from './RealizationStatusCash';
import RealizationMessageOnline from './RealizationMessageOnline';
import RealizationStatusOnline from './RealizationStatusOnline';
import StatusForm from './StatusForm';
import DeliveryIcon from '../Icons/Delivery';
import ArrowIcon from '../Icons/Arrow';
import Loader from '../Loader/Loader';
import FailIcon from '../Icons/Fail';

class OrderStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      etap: 0,
      isActive: false,
      message: 'Brak zamówienia',
      endpoint: process.env.REACT_APP_API_SOCKET
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.clearOrder = this.clearOrder.bind(this);
    this.realizationEtap = this.realizationEtap.bind(this);
  }

  handleClose() { this.setState({ show: false }) };
  handleShow() { this.setState({ show: true }) };
  clearOrder() { if (this.props.order.status === 'Zrealizowane') { localStorage.removeItem("OrderId"); this.props.clearActiveOrder(); } }

  callbackFunction = (childData) => {
    this.setState({ isActive: true })
  }

  realizationEtap() {
    if (this.props.order.status === "Płatność...") {
      this.setState({ etap: 1, message: 'Płatność za twoje zamówienie jest przetwarzana...' })
    }
    else if (this.props.order.status === "Przyjęte") {
      this.setState({ etap: 2, message: 'Twoje zamówienie zostało przyjęte do realizacji' })
    }
    else if (this.props.order.status === "Przygotowanie") {
      this.setState({ etap: 3, message: 'Twoje zamówienie jest przygotowywane' })
    }
    else if (this.props.order.status === "W Drodze") {
      this.setState({ etap: 4, message: 'Twoje zamówienie jest dostarczane' })
    }
    else if (this.props.order.status === "Zrealizowane") {
      this.setState({ etap: 5, message: 'Twoje zamówienie zostało dostarczone. Smacznego!' })
    }
  }

  componentDidMount() {

    const { endpoint } = this.state;
    const socket = socketIOClient(endpoint);
    if (localStorage.getItem("OrderId")) {
      this.props.getOrder(localStorage.getItem("OrderId"));
      this.setState({ show: true });
      socket.emit('create', localStorage.getItem("OrderId"));
      socket.on("PaymentStatus", data => {
        this.props.updateOrder(data.order);
        this.realizationEtap();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeOrderId !== prevProps.activeOrderId) {

      const { endpoint } = this.state;
      const socket = socketIOClient(endpoint);
      if (localStorage.getItem("OrderId")) {
        this.props.getOrder(localStorage.getItem("OrderId"));
        this.setState({ show: true })
        socket.emit('create', localStorage.getItem("OrderId"));
        socket.on("PaymentStatus", data => {
          this.props.updateOrder(data.order);
          this.realizationEtap();
        });
      }
    }
  }

  render() {

    if (this.props.loading) {
      return <div>
        <div className="modal" style={{ display: this.state.show === true ? 'block' : 'none' }}>
          <div className="modal-main">
            <div><Loader /></div>
          </div>
        </div>
      </div>
    }
    if (this.props.error) {
      return <div>
        <div className="modal" style={{ display: this.state.show === true ? 'block' : 'none' }}>
          <div className="modal-main">
            <div>
              <h2 style={{ maxWidth: '400px' }}>{this.props.error}</h2>

              <StatusForm parentCallback={this.callbackFunction} />
            </div>
            <div onClick={this.handleClose} className="modal__close">
              <FailIcon className="modal__close_icon" classNamePath="modal__close_icon_path" />
            </div>
          </div>
        </div>

        <button className="trackOrder__button" onClick={this.handleShow} style={{ display: this.props.newOrder.length === 0 ? 'flex' : 'none' }}>
          <div className="trackOrder__inner">
            <div className="cart__icon__box"><DeliveryIcon className="cart__icon" /></div>
            <div className="cart__content"><div className="trackOrder__title">Śledź zamówienie</div></div>
            <div className="cart__price"><ArrowIcon className="cart__icon" /></div>
          </div>
        </button>
      </div>
    }
    return (
      <div>
        {this.props.order.length < 1 ?
          <div>
            <div className="modal" style={{ display: this.state.show === true ? 'block' : 'none' }}>
              <div className="modal-main">
                <div>
                  <h2>Brak aktywnego zamówienia</h2>

                  <StatusForm parentCallback={this.callbackFunction} />
                </div>
                <div onClick={this.handleClose} className="modal__close">
                  <FailIcon className="modal__close_icon" classNamePath="modal__close_icon_path" />
                </div>
              </div>
            </div>

            <button className="trackOrder__button" onClick={this.handleShow} style={{ display: this.props.newOrder.length === 0 ? 'flex' : 'none' }}>
              <div className="trackOrder__inner">
                <div className="cart__icon__box"><DeliveryIcon className="cart__icon" /></div>
                <div className="cart__content"><div className="trackOrder__title">Śledź zamówienie</div></div>
                <div className="cart__price"><ArrowIcon className="cart__icon" /></div>
              </div>
            </button>
          </div>
          :
          <div>
            <div className="modal" style={{ display: this.state.show === true ? 'block' : 'none' }}>
              <div className="modal-main">
                <div>
                  <h2>Twoje zamówienie</h2>
                  <h3>nr #{this.props.order.orderNumber}</h3>

                  {this.props.order.realization === "pick-up"
                    ?

                    this.props.order.paymentType === "p-gg"
                      ?
                      <>
                        <RealizationStatusCash status={this.props.order.status} />
                        <RealizationMessageCash status={this.props.order.status} />
                      </>
                      :
                      <>
                        <RealizationStatusOnline status={this.props.order.status} />
                        <RealizationMessageOnline status={this.props.order.status} />
                      </>
                    :
                    <>
                      <RealizationStatus status={this.props.order.status} />
                      <RealizationMessage status={this.props.order.status} />
                    </>
                  }

                </div>
                <div onClick={() => { this.handleClose(); this.clearOrder(); }} className="modal__close">
                  <FailIcon className="modal__close_icon" classNamePath="modal__close_icon_path" />
                </div>
              </div>
            </div>
            <button className="trackOrder__button" onClick={this.handleShow} style={{ display: this.props.newOrder.length === 0 ? 'flex' : 'none' }}>
              <div className="trackOrder__inner">
                <div className="cart__icon__box"><DeliveryIcon className="cart__icon" /></div>
                <div className="cart__content"><div className="trackOrder__title">Śledź zamówienie</div></div>
                <div className="cart__price"><ArrowIcon className="cart__icon" /></div>
              </div>
            </button>
          </div>}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getOrder: item => dispatch(getOrder(item)),
    updateOrder: item => dispatch(updateOrder(item)),
    clearActiveOrder: () => dispatch(clearActiveOrder())
  }
}

function mapStateToProps(state) {
  return {
    order: state.activeOrder,
    newOrder: state.order,
    activeOrderId: state.activeOrderId,
    loading: state.orderLoading,
    error: state.orderError
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatus);
