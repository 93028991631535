import React from 'react';

const BuildingIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="269.254" height="307.719" viewBox="0 0 269.254 307.719">
  <path className={`${classNamePath || ""}`} id="building" d="M262.042,288.486h-12.02V14.424A14.424,14.424,0,0,0,235.6,0H33.657A14.424,14.424,0,0,0,19.232,14.424V288.486H7.212A7.212,7.212,0,0,0,0,295.7v12.02H269.254V295.7A7.212,7.212,0,0,0,262.042,288.486ZM76.93,45.677a7.212,7.212,0,0,1,7.212-7.212h24.041a7.212,7.212,0,0,1,7.212,7.212V69.718a7.212,7.212,0,0,1-7.212,7.212H84.142a7.212,7.212,0,0,1-7.212-7.212Zm0,57.7a7.212,7.212,0,0,1,7.212-7.212h24.041a7.212,7.212,0,0,1,7.212,7.212v24.041a7.212,7.212,0,0,1-7.212,7.212H84.142a7.212,7.212,0,0,1-7.212-7.212Zm31.253,88.95H84.142a7.212,7.212,0,0,1-7.212-7.212V161.072a7.212,7.212,0,0,1,7.212-7.212h24.041a7.212,7.212,0,0,1,7.212,7.212v24.041A7.212,7.212,0,0,1,108.182,192.324Zm45.677,96.162H115.395V238a7.212,7.212,0,0,1,7.212-7.212h24.041A7.212,7.212,0,0,1,153.859,238Zm38.465-103.374a7.212,7.212,0,0,1-7.212,7.212H161.072a7.212,7.212,0,0,1-7.212-7.212V161.072a7.212,7.212,0,0,1,7.212-7.212h24.041a7.212,7.212,0,0,1,7.212,7.212Zm0-57.7a7.212,7.212,0,0,1-7.212,7.212H161.072a7.212,7.212,0,0,1-7.212-7.212V103.374a7.212,7.212,0,0,1,7.212-7.212h24.041a7.212,7.212,0,0,1,7.212,7.212Zm0-57.7a7.212,7.212,0,0,1-7.212,7.212H161.072a7.212,7.212,0,0,1-7.212-7.212V45.677a7.212,7.212,0,0,1,7.212-7.212h24.041a7.212,7.212,0,0,1,7.212,7.212Z"/>
</svg>);

export default BuildingIcon;