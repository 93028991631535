import React from 'react';
import './info.css';
import { connect } from 'react-redux';
import { getInfos } from '../../js/actions/index';
import MailIcon from '../Icons/Mail';
import DeliveryIcon from '../Icons/Delivery';
import InfoIcon from '../Icons/Info';
import MapmarkerIcon from '../Icons/Mapmarker';
import ClockIcon from '../Icons/Clock';
import MoneyIcon from '../Icons/Money';

import PhoneIcon from '../Icons/Phone';
import CartIcon from '../Icons/Cart';

import Chevron from '../Accordion/Chevron';
import '../Accordion/Accordion.css';

function mapDispatchToProps(dispatch) {
  return {
    getInfos: () => dispatch(getInfos()),
  };
}

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setActive: "",
      setHeight: "0px",
      setRotate: "accordion__icon",
    };

    this.content = React.createRef();
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  toggleAccordion() {
    this.setState({ setActive: (this.state.setActive === "" ? "active" : "") });
    this.setState({ setHeight: (this.state.setActive === "active" ? "0px" : `${this.content.current.scrollHeight}px`) });
    this.setState({ setRotate: (this.state.setActive === "active" ? "accordion__icon" : "accordion__icon rotate") });
  }

  componentDidMount() {
    this.props.getInfos();
  }

  render() {

    return (
      <div className="info">
        {this.props.infos.filter(item => item.local === this.props.activeLocalization).map(item =>
          <div key={item._id} className="info__mobile">
            <div key={this.props.id} className="accordion__section">
              <button className={`accordion_sec ${this.state.setActive}`} onClick={this.toggleAccordion}>
                <div className="accordion__photo">
                  <InfoIcon className="info__icon" classNamePath="info__box__path" />
                </div>
                <div className="accordion__data">
                  <p className="accordion__title">Pokaż informacje</p>
                </div>
                <Chevron className={`${this.state.setRotate}`} width={28} height={28} fill={"#777"} />
              </button>

              <div ref={this.content} style={{ maxHeight: `${this.state.setHeight}` }} className="accordion__content">
                <div className="info__box">
                  <div className="info__box__icon">
                    <InfoIcon className="info__icon" classNamePath="info__box__path" />
                  </div>
                  <h4>O nas</h4>
                  <p>
                    {item.about}
                  </p>
                </div>
                <div className="info__box">
                  <div className="info__box__icon">
                    <MapmarkerIcon className="info__icon" classNamePath="info__box__path" />
                  </div>
                  <h4>Lokalizacja</h4>
                  <p>
                    {item.address}
                  </p>
                </div>
                <div className="info__box">
                  <div className="info__box__icon">
                    <ClockIcon className="info__icon" classNamePath="info__box__path" />
                  </div>
                  <h4>Godziny otwarcia</h4>
                  {/* <div>{item.openHours}</div> */}
                  <div> Pon - Pt: 11.30 - 21.00</div>
                  <div> Sob - Nd: 12.00 - 21.00</div>
                </div>
                <div className="info__box">
                  <div className="info__box__icon">
                    <DeliveryIcon className="info__icon" classNamePath="info__box__path" />
                  </div>
                  <h4>Dostawa</h4>
                  <div className="info__data"> <MoneyIcon className="info__icon_small" classNamePath="info__icon_small__path" />
                    {this.props.activeLocalization === '5f51a6b211492756ceb12bb6' ? 'min. 20.00 ZŁ' : 'min. 20.00 ZŁ'}
                  </div>
                  <div className="info__data"> <DeliveryIcon className="info__icon_small" classNamePath="info__icon_small__path" /> {item.deliveryPrice}</div>
                  <div className="info__data"> <CartIcon className="info__icon_small" classNamePath="info__icon_small__path" /> Opakowanie 1 ZŁ</div>
                </div>
                <div className="info__box">
                  <div className="info__box__icon">
                    <MailIcon className="info__icon" classNamePath="info__box__path" />
                  </div>
                  <h4>Kontakt</h4>
                  <div className="info__data"> <PhoneIcon className="info__icon_small" classNamePath="info__icon_small__path" /> <a href={`tel:+48${item.phone}`}>{item.phone} </a> </div>
                  <div className="info__data"> <MailIcon className="info__icon_small" classNamePath="info__icon_small__path" /> {item.email} </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.props.infos.filter(item => item.local === this.props.activeLocalization).map(item =>
          <div key={item._id} className="info__desktop">
            <div className="info__container">
              <div className="info__box">
                <div className="info__box__icon">
                  <InfoIcon className="info__icon" classNamePath="info__box__path" />
                </div>
                <h4>O nas</h4>
                <p>
                  {item.about}
                </p>
              </div>
              <div className="info__box">
                <div className="info__box__icon">
                  <MapmarkerIcon className="info__icon" classNamePath="info__box__path" />
                </div>
                <h4>Lokalizacja</h4>
                <p>
                  {item.address}
                </p>
              </div>
              <div className="info__box">
                <div className="info__box__icon">
                  <ClockIcon className="info__icon" classNamePath="info__box__path" />
                </div>
                <h4>Godziny otwarcia</h4>

                <div>{this.props.activeLocalization === '5f51a6b211492756ceb12bb6' ? 'Pon - Wt: Zamknięte' : null}</div>
                <div>{this.props.activeLocalization === '5f51a6b211492756ceb12bb6' ? 'Śr, Czw, Sob, Nd: 12.00 - 20.00' : null}</div>
                <div>{this.props.activeLocalization === '5f51a6b211492756ceb12bb6' ? 'Pt: 12.00 - 20.00' : null}</div>

                <div>{this.props.activeLocalization === '62cd99801c8e354805c7dbcd' ? 'Pon - Pt: 11.30 - 20.00' : null}</div>
                <div>{this.props.activeLocalization === '62cd99801c8e354805c7dbcd' ? 'Sob - Nd: 12.00 - 20.00' : null}</div>

                <div>{this.props.activeLocalization === '61839008d9cfab330b97f5d1' ? 'Nd - Pon: Zamknięte' : null}</div>
                <div>{this.props.activeLocalization === '61839008d9cfab330b97f5d1' ? 'Wt - Czw: 11.30 - 20.00' : null}</div>
                <div>{this.props.activeLocalization === '61839008d9cfab330b97f5d1' ? 'Pt: 11.30 - 20.00' : null}</div>
                <div>{this.props.activeLocalization === '61839008d9cfab330b97f5d1' ? 'Sob: 12.00 - 20.00' : null}</div>


                {/* <div>{item.openHours}</div> */}
              </div>
              <div className="info__box">
                <div className="info__box__icon">
                  <DeliveryIcon className="info__icon" classNamePath="info__box__path" />
                </div>
                <h4>Dostawa</h4>
                <div className="info__data"> <MoneyIcon className="info__icon_small" classNamePath="info__icon_small__path" />
                  {this.props.activeLocalization === '5f51a6b211492756ceb12bb6' ? 'min. 40.00 ZŁ' : 'min. 40.00 ZŁ'}

                </div>
                <div className="info__data"> <DeliveryIcon className="info__icon_small" classNamePath="info__icon_small__path" />{item.deliveryPrice}</div>
                <div className="info__data"> <CartIcon className="info__icon_small" classNamePath="info__icon_small__path" /> Opakowanie 1 ZŁ</div>
              </div>
              <div className="info__box">
                <div className="info__box__icon">
                  <MailIcon className="info__icon" classNamePath="info__box__path" />
                </div>
                <h4>Kontakt</h4>
                <div className="info__data"> <PhoneIcon className="info__icon_small" classNamePath="info__icon_small__path" /> <a href={`tel:+48${item.phone}`}>{item.phone} </a></div>
                <div className="info__data"> <MailIcon className="info__icon_small" classNamePath="info__icon_small__path" /> {item.email} </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    infos: state.infos,
    loading: state.infosLoading,
    error: state.infosError,
    activeLocalization: state.activeLocalization
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);
