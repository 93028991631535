import React from "react";
import validateLoginData from './Validations/login';
import { connect } from "react-redux";
import { loginUser } from "../../js/actions/index";
import { Link } from 'react-router-dom';
import Alert from "../Alerts/Alert";
import isEmpty from 'lodash/isEmpty';

function mapDispatchToProps(dispatch) {
  return {
    loginUser: article => dispatch(loginUser(article))
  };
}

function mapStateToProps(state){
  return {
      user: state.user,
      loginAlert: state.loginAlert
  }
}

class ConnectedForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      isLoading: false,
      user: {},
      alert: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.first = this.first.bind(this);
  }

  isValid(){
    const { errors, isValid } = validateLoginData(this.state);
    console.log('check');
    if(!isValid){
        this.setState({ errors });
    }

    return isValid;
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    if(this.isValid()){
        this.setState({ errors: {}, isLoading: true });
        this.props.loginUser(this.state).then(
            (res) => this.setState({ email: "", password: ""}),
            (err) => this.setState({ errors: err.data.errors, isLoading: false }),
        )
    }
  }

  first(v){
    return v[Object.keys(v)[0]];
  };


  render() {
    const { email, password, errors } = this.state;
    return (
      <div>
        {isEmpty(this.state.errors) ? '' : <Alert alertMessage={this.first(this.state.errors)} type="error" />}
        {isEmpty(this.props.loginAlert) ? '' : <Alert alertMessage={this.props.loginAlert.message} type="error" />}
        <form onSubmit={this.handleSubmit}>
          <div className="mt-1">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                className="form-control"
                type="text"
                id="email"
                value={email}
                onChange={this.handleChange}
                error={errors.email}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Hasło</label>
              <input
                className="form-control"
                type="password"
                id="password"
                value={password}
                onChange={this.handleChange}
                error={errors.password}
              />
            </div>
          </div>
          <button type="submit">Zaloguj się</button>
        </form>
        <div className="shortcut">
          <p>Nie masz konta? <Link to="/signup" className="">Zarejestruj się</Link></p>
        </div>
      </div>
    );
  }
}

const Form = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedForm);

export default Form;