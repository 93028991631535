import React from 'react';
import OrderList from './CheckoutComponents/OrderList';
import PromoCode from './CheckoutComponents/PromoCode';
import Delivery from './CheckoutComponents/Delivery';
import Payment from './CheckoutComponents/Payment';
import Summary from './CheckoutComponents/Summary';
import validateOrderData from './Validations/order';
import { addOrder } from '../../js/actions/index';
import { connect } from "react-redux";
import '../LoginSystem/LoginSystem.css';
import Alert from "../Alerts/Alert";
import isEmpty from 'lodash/isEmpty';
import RealizationTime from './CheckoutComponents/RealizationTime';
//import { getAuth } from '../../js/actions/index';
import Validator from 'validator';

function mapDispatchToProps(dispatch) {
  return {
    addOrder: (data, realPrice) => dispatch(addOrder(data, realPrice))
  };
}

class ConnectedCheckoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user_id,
      errors: {},
      orders: "enabled",
    };

    this.handleOrder = this.handleOrder.bind(this);
    this.first = this.first.bind(this);
  }



  isValid() {
    const { errors, isValid } = validateOrderData(this.props, Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0) + Number(this.props.deliveryPrice) + Number(this.props.wrapPrice)));
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  }

  first(v) {
    return v[Object.keys(v)[0]];
  };

  handleOrder() {
    if (this.state.orders === "disabled") {
      this.setState({ errors: { disabled: "Zamówienia są tymczasowo niedostępne. Zadzwoń i zamów 796 215 484" } })
    }
    else {
      if (this.isValid()) {
        this.setState({ errors: {} });
        let realPrice = Number(this.props.order.map(item => item.amount * item.price).reduce((pv, cv) => pv + cv, 0) + Number(this.props.deliveryPrice) + Number(this.props.wrapPrice)).toFixed(2);

        addOrder(this.props, realPrice);
      }
      else {
        //console.log("ERRORS", this.state.errors);
      }
    }
  }

  render() {

    return (
      <div className="main">
        <div className="container__new__checkout">
          <div className="wide__column">
            <h1>Twoje zamówienie</h1>
            <OrderList />
            <Summary />
            <Payment paymentMethods={this.props.paymentMethods} />
          </div>
          <div className="sidebar__column">
            <Delivery />
            <RealizationTime />
            <PromoCode />
            {isEmpty(this.state.errors) ? '' : <Alert alertMessage={this.first(this.state.errors)} type="error" />}
            <button onClick={this.handleOrder}> Zamawiam i płacę </button>

          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user_id: state.user.user.user,
    order: state.order,
    name: state.name,
    surname: state.surname,
    email: state.email,
    phone: state.phone,
    cutlery: state.cutlery,
    distance: state.distance,

    finalPrice: state.finalPrice,
    price: state.price,
    deliveryPrice: state.deliveryPrice,

    paymentType: state.paymentType,
    paymentTypeName: state.paymentTypeName,

    building_number: state.building_number,
    street: state.street,
    locality: state.locality,
    postal_code: state.postal_code,
    local: state.local,
    rodo: state.rodo,

    additionalInfo: Validator.escape(state.additionalInfo),

    realization: state.realization,

    vicinity: state.vicinity,

    usedCode: state.usedCode,
    promoType: state.promoType,
    newOrderId: state.newOrderId,
    wrapPrice: state.wrapPrice,
    place: state.place,

    local_code: state.activeLocalization,
    localizations: state.localizations,

    realizationTime: state.realizationTime,
    realizationTimeValue: state.realizationTimeValue
  }
}



const CheckoutPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedCheckoutPage);

export default CheckoutPage;
