import React from 'react';

const ChefIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="873.663" height="874.9" viewBox="0 0 873.663 874.9">
  <path className={`${classNamePath || ""}`} id="chef" d="M932.5,398.1a192.06,192.06,0,0,0-37.3-77.3c-16-40.3-43.7-70.3-83-89-6.8-3.2-9.5-6.4-10-14.3A164.227,164.227,0,0,0,656.5,64.4c-7.7-1-15.6-1.3-23.4-1.8l-35.8,4.9L583,72.6a161.621,161.621,0,0,0-74,53c-43.1.8-86.3,14.8-122,44-29.8,24.3-51.6,54.5-69.4,90-46.3-15-91.6-15.1-136.2,3.3-45,18.5-78.2,50.1-99.5,93.7C46,430.2,63.3,522,122.9,577c30.2,27.8,65.5,45.3,106.2,50.1,57.6,6.7,108.4-10,152.3-47.7,3.7,2.7,6.6,4.7,9.4,6.8,70.7,52.8,155.3,56.4,229.2,9.4,7.3-4.7,12.3-5.1,19.8,0,42.6,28.8,89.5,38.3,140.4,30,104.2-17.2,175.7-124.5,152.3-227.5ZM622.4,635.9c-79.2,40.1-156.5,37.5-232.6-7.8-5.8-3.6-9.7-3.6-15.2.4-26.9,17.5-56.3,28.8-87.9,32.6-9.4,1.3-11.6,4.2-11.3,13.3.3,40.6.1,81.2,0,122v77.3H745.7V664.4c-40.1-1.3-77-10.7-111-29.8C631.8,632.6,625.9,634,622.4,635.9ZM275.3,886.5H745.6v51H275.3v-51Z" transform="translate(-63.289 -62.6)"/>
</svg>
);

export default ChefIcon;