import React from 'react';

const DeliveryIcon = ({
  className ="",
  classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="384.648" height="307.719" viewBox="0 0 384.648 307.719">
  <path className={classNamePath} id="shipping-fast" d="M375.032,211.557h-9.616v-64.97a28.85,28.85,0,0,0-8.474-20.374L296.9,66.172A28.85,28.85,0,0,0,276.526,57.7h-26.5V28.849A28.856,28.856,0,0,0,221.173,0H67.313A28.856,28.856,0,0,0,38.465,28.849V57.7H4.808A4.822,4.822,0,0,0,0,62.505v9.616A4.822,4.822,0,0,0,4.808,76.93H168.284a4.822,4.822,0,0,1,4.808,4.808v9.616a4.822,4.822,0,0,1-4.808,4.808H24.041a4.822,4.822,0,0,0-4.808,4.808v9.616a4.822,4.822,0,0,0,4.808,4.808H149.051a4.822,4.822,0,0,1,4.808,4.808v9.616a4.822,4.822,0,0,1-4.808,4.808H4.808A4.822,4.822,0,0,0,0,139.435v9.616a4.822,4.822,0,0,0,4.808,4.808H129.819a4.822,4.822,0,0,1,4.808,4.808v9.616a4.822,4.822,0,0,1-4.808,4.808H38.465v76.93a57.7,57.7,0,0,0,115.395,0h76.93a57.7,57.7,0,1,0,115.395,0h28.849a9.644,9.644,0,0,0,9.616-9.616V221.173A9.644,9.644,0,0,0,375.032,211.557ZM96.162,278.87a28.849,28.849,0,1,1,28.849-28.849A28.856,28.856,0,0,1,96.162,278.87Zm192.324,0a28.849,28.849,0,1,1,28.849-28.849A28.856,28.856,0,0,1,288.486,278.87Zm48.081-125.011H250.022V86.546h26.5l60.041,60.041Z" fill="#152839"/>
</svg>);

export default DeliveryIcon;
