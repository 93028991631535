import React from 'react';

const MapmarkerIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="230.789" height="307.719" viewBox="0 0 230.789 307.719">
  <path className={`${classNamePath || ""}`} id="map-marker-alt" d="M103.535,301.51C16.209,174.914,0,161.921,0,115.395a115.395,115.395,0,0,1,230.789,0c0,46.526-16.209,59.519-103.535,186.116a14.431,14.431,0,0,1-23.718,0Zm11.859-138.035a48.081,48.081,0,1,0-48.081-48.081A48.081,48.081,0,0,0,115.395,163.476Z"/>
</svg>);

export default MapmarkerIcon;