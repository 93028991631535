import React from 'react';
import { connect } from "react-redux";
import { logout } from '../../js/actions/index';
import { Link } from 'react-router-dom';
import LoginIcon from '../Icons/Logoin';
import LogoutIcon from '../Icons/Logout';
import './LogInButton.css';

function mapStateToProps(state){
  return {
      user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: (() => dispatch(logout()))
  };
}

class ConnectedLogInButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        user: this.props.user
    };
  }

  render(){

    console.log('button',this.props.user);
    return (
      this.props.user.loggedIn
      ? <button className="login_btn" onClick={() => this.props.onClick()}>
            <div className="login_btn__text">
              {/* {this.props.user.user.user} */}
              Wyloguj
            </div>
            <LogoutIcon className="login__icon" classNamePath="login__icon__path" />
        </button>
      : <Link to="/login">
          <button className="login_btn">
            <div className="login_btn__text">
              Zaloguj się
            </div>
            <LoginIcon className="login__icon" classNamePath="login__icon__path" />
          </button>
        </Link>

    );
  }
}

const LogInButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedLogInButton);

export default LogInButton;