import React from "react";

function Chevron(props) {

 return (
   <svg
     className={props.className}
     height={props.height}
     width={props.width}
     fill={props.fill}
     viewBox="0 0 30 30"
     xmlns="http://www.w3.org/2000/svg"
   >
     <path d="M18.629 15.997l-7.083-7.081L13.462 7l8.997 8.997L13.457 25l-1.916-1.916z"/>

    </svg>
   );
}

export default Chevron;