import React from 'react';
import './App.css';
import { connect } from "react-redux";
import LoginPage from './components/LoginSystem/LoginPage';
import SignupPage from './components/LoginSystem/SignupPage';
import CheckoutPage from './components/Cart/CheckoutPage';
import Cart from './components/Cart/Cart';
import Hero from './components/hero/Hero';
import Header from './components/header/Header';
import Footer from './components/Footer/Footer'
import { history } from './js/helpers/history';
import CategoryList from './components/Accordion/CategoryList';
import OrderHistory from './components/OrderHistory/OrderHistory';
import Info from './components/Info/Info';
import OrderStatus from './components/OrderStatus/OrderStatus';
import Politics from './components/regulations/Politics';
import Regulations from './components/regulations/Regulations';
import PromotionsRegulations from './components/regulations/PromotionsRegulations';
import { getPaymentToken } from './js/actions/index';
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

ReactGA.initialize('UA-118645293-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentToken: order => dispatch(getPaymentToken()),
    loadUserFromToken: () => {
      let user = localStorage.getItem('user');
      let token = user.token;
      if (!token || token === '') {
        return;
      }
      else {
        return token;
      }
    }
  }
}

class ConnectedApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: {}
    };
  }

  componentDidMount() {
    this.props.getPaymentToken();
  }

  render() {
    return (
      <BrowserRouter history={history} basename="/">
        <div className="App">
          <Header />
          <div>
            <Hero />
          </div>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/signup">
              <SignupPage />
            </Route>
            <Route path="/checkout">
              <CheckoutPage />
            </Route>
            <Route path="/polityka-prywatnosci">
              <Politics />
            </Route>
            <Route path="/regulamin">
              <Regulations />
            </Route>
            <Route path="/regulamin-promocji">
              <PromotionsRegulations />
            </Route>
            <Route path="/" >
              <OrderStatus />
              <Info />
              <div className="main">
                {/* <OrderHistory /> */}
                <CategoryList />
              </div>
              <Cart />
            </Route>
          </Switch>
          <CookieConsent
            location="bottom"
            buttonText="Zgoda"
            style={{ background: "#152839", padding: "6px 30px 5px 10px" }}
            buttonStyle={{ background: "#28B8C1", color: "#fff", fontSize: "13px", marginRight: "10px" }}
            expires={150}
          >
            Ta strona korzysta z ciasteczek. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich używanie.{" "}
          </CookieConsent>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

const App = connect(
  null,
  mapDispatchToProps
)(ConnectedApp);

export default App;
