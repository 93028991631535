import React from 'react';

const InfoIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="298.103" height="298.103" viewBox="0 0 298.103 298.103">
  <path className={`${classNamePath || ""}`} id="info-circle" d="M157.051,8A149.051,149.051,0,1,0,306.1,157.051,149.074,149.074,0,0,0,157.051,8Zm0,66.111a25.243,25.243,0,1,1-25.243,25.243A25.243,25.243,0,0,1,157.051,74.111Zm33.657,152.657a7.212,7.212,0,0,1-7.212,7.212H130.607a7.212,7.212,0,0,1-7.212-7.212V212.345a7.212,7.212,0,0,1,7.212-7.212h7.212V166.668h-7.212a7.212,7.212,0,0,1-7.212-7.212V145.031a7.212,7.212,0,0,1,7.212-7.212h38.465a7.212,7.212,0,0,1,7.212,7.212v60.1H183.5a7.212,7.212,0,0,1,7.212,7.212Z" transform="translate(-8 -8)"/>
</svg>);

export default InfoIcon;