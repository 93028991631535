import React from 'react';

const MailIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="307.719" height="230.789" viewBox="0 0 307.719 230.789">
  <path className={`${classNamePath || ""}`} id="envelope" d="M301.889,140.209a3.618,3.618,0,0,1,5.83,2.825V265.94a28.856,28.856,0,0,1-28.849,28.849H28.849A28.856,28.856,0,0,1,0,265.94V143.093a3.594,3.594,0,0,1,5.83-2.825c13.463,10.458,31.313,23.74,92.616,68.275,12.681,9.256,34.077,28.728,55.413,28.608,21.456.18,43.273-19.713,55.474-28.608C270.636,164.009,288.426,150.666,301.889,140.209Zm-148.03,77.651c13.944.24,34.017-17.55,44.114-24.882,79.754-57.878,85.825-62.926,104.216-77.35a14.382,14.382,0,0,0,5.529-11.359V92.849A28.856,28.856,0,0,0,278.87,64H28.849A28.856,28.856,0,0,0,0,92.849v11.419a14.47,14.47,0,0,0,5.529,11.359c18.391,14.364,24.461,19.473,104.216,77.35,10.1,7.332,30.171,25.122,44.114,24.882Z" transform="translate(0 -64)"/>
</svg>);

export default MailIcon;