import React from 'react';
import { connect } from "react-redux";
import './Footer.css';
import { Link } from 'react-router-dom';
// import HeroLogo from '../../images/hero-logo.png';
import SocialIcon from '../header/SocialIcon';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <footer>
        <div className="footer__container">
          <div className="footer__content">
            <div className="footer__item">
              <SocialIcon link="https://www.facebook.com/MozeRyba" icon="facebook" color="#4267B2" />
              <SocialIcon link="https://www.instagram.com/moze_ryba_fish_chips/?hl=pl" icon="instagram" color="#E1306C" />
              <SocialIcon link="https://pl.tripadvisor.com/Restaurant_Review-g274823-d13478003-Reviews-Moze_Ryba_Fish_Chips-Bydgoszcz_Kuyavia_Pomerania_Province_Central_Poland.html" icon="tripadvisor" color="#00AF87" />
              <SocialIcon link="https://www.google.com/maps/place/Mo%C5%BCe+Ryba+-+Fish+%26+Chips/@53.1285451,18.0037871,17z/data=!3m1!4b1!4m5!3m4!1s0x470313bf5173f22b:0x6a0693eb3ad7a8d4!8m2!3d53.1285451!4d18.0059758" icon="google" color="#4285F4" />
            </div>
            {/* <div className="footer__item">
                            <div className="footer__logo">
                                <img src={HeroLogo} alt="Logo"></img>
                            </div>
                        </div> */}
            <div className="footer__item">

            </div>
          </div>
        </div>
        <div className="copy">
          <div className="footer__container">
            <div className="footer__content">
              <div className="footer__item">
                copyrights MozeRyba.pl
                            </div>
              <div className="footer__item">
                <Link className="fot__link" to="/regulamin">Regulamin </Link>  |  <Link className="fot__link" to="/polityka-prywatnosci"> Polityka Prywatności</Link> | <Link className="fot__link" to="/regulamin-promocji"> Regulamin Promocji</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default connect(null, null)(Footer);