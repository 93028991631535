import React from 'react';

const StopwatchIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="250.037" height="307.719" viewBox="0 0 250.037 307.719">
  <path className={`${classNamePath || ""}`} id="stopwatch" d="M266.022,182.708A125.011,125.011,0,1,1,121.778,59.2V38.465H104.95a7.233,7.233,0,0,1-7.212-7.212V7.212A7.233,7.233,0,0,1,104.95,0h72.122a7.233,7.233,0,0,1,7.212,7.212V31.253a7.233,7.233,0,0,1-7.212,7.212H160.243V59.2a124.249,124.249,0,0,1,59.921,26.805l16.528-16.528a7.219,7.219,0,0,1,10.217,0l17.009,17.009a7.219,7.219,0,0,1,0,10.217l-17.67,17.67-.361.361A123.761,123.761,0,0,1,266.022,182.708ZM160.243,204.345V113.291a7.233,7.233,0,0,0-7.212-7.212H128.991a7.233,7.233,0,0,0-7.212,7.212v91.054a7.233,7.233,0,0,0,7.212,7.212h24.041A7.233,7.233,0,0,0,160.243,204.345Z" transform="translate(-16)"/>
</svg>);

export default StopwatchIcon;