import React from 'react';

const PercentIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="269.254" height="307.719" viewBox="0 0 269.254 307.719">
  <path id="percent" d="M67.313,134.627A67.313,67.313,0,1,0,0,67.313,67.28,67.28,0,0,0,67.313,134.627Zm0-96.162A28.849,28.849,0,1,1,38.465,67.313,28.856,28.856,0,0,1,67.313,38.465ZM201.94,173.092a67.313,67.313,0,1,0,67.314,67.314A67.28,67.28,0,0,0,201.94,173.092Zm0,96.162a28.849,28.849,0,1,1,28.849-28.849A28.856,28.856,0,0,1,201.94,269.254ZM235.778.12,254.77.06a14.424,14.424,0,0,1,11.84,22.718L46.518,301.468a14.394,14.394,0,0,1-11.78,6.13l-20.074.06A14.424,14.424,0,0,1,2.825,284.94L224,6.251A14.394,14.394,0,0,1,235.778.12Z"/>
</svg>
);

export default PercentIcon;