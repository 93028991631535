import React from 'react';

const PickupIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="384.667" height="307.719" viewBox="0 0 384.667 307.719">
  <path className={`${classNamePath || ""}`} id="people-carry" d="M76.924,57.7A28.849,28.849,0,1,0,48.075,28.849,28.856,28.856,0,0,0,76.924,57.7Zm230.789,0a28.849,28.849,0,1,0-28.849-28.849A28.856,28.856,0,0,0,307.713,57.7ZM383.26,281.334l-26.445-66.111-24.7,27.887-1.2,10.938,16.648,41.59a19.216,19.216,0,0,0,17.85,12.08,18.687,18.687,0,0,0,7.152-1.382,19.224,19.224,0,0,0,10.7-25ZM362.706,155.242l-11.119-48.2a38.95,38.95,0,0,0-22.538-26.985,37.083,37.083,0,0,0-33.717,1.983,65.847,65.847,0,0,0-28.909,35.7l-6.791,20.374-9.616,6.13v-57.7A9.644,9.644,0,0,0,240.4,76.93H144.237a9.644,9.644,0,0,0-9.616,9.616v57.7l-9.676-6.13-6.791-20.374a65.378,65.378,0,0,0-28.909-35.7,37.094,37.094,0,0,0-33.717-1.983A38.95,38.95,0,0,0,32.99,107.04l-11.059,48.2a38.506,38.506,0,0,0,8.655,34.077l40.388,45.617,6.07,55.654a19.248,19.248,0,0,0,19.112,17.129c.721,0,1.382-.06,2.1-.12a19.2,19.2,0,0,0,17.009-21.216l-6.07-55.774a38.412,38.412,0,0,0-9.376-21.035L73.8,180.124l10.578-42.251,4.087,12.261c2.464,7.513,7.152,14.064,14.725,19.593L133.9,189.259c2.765,1.743,7.272,2.765,10.337,3.005H240.4c3.065-.24,7.573-1.262,10.337-3.005l30.712-19.533c7.573-5.529,12.261-12.02,14.725-19.593l4.087-12.261,10.578,42.251-26.024,29.45a38.412,38.412,0,0,0-9.376,21.035l-6.07,55.774A19.244,19.244,0,0,0,286.377,307.6c.721.06,1.382.12,2.1.12a19.242,19.242,0,0,0,19.112-17.129l6.07-55.654,40.388-45.617A38.683,38.683,0,0,0,362.706,155.242ZM27.821,215.223,1.376,281.334a19.23,19.23,0,0,0,35.7,14.3l16.648-41.59-1.2-10.938-24.7-27.887Z" transform="translate(0.015)"/>
</svg>
);

export default PickupIcon;