import React from 'react';
import HeroLogo from '../../images/hero-logo.png';
import OpenSign from './OpenSign.js';
import SelectLocal from './SelectLocal.js';
import '../../App.css';
import './Hero.css';
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <div className="hero">
        <div className="hero__content">
            <Link to="/">
              <div className="hero__logo">
                  <img className="hero__logo_img" src={HeroLogo} alt="Logo"></img>
              </div>
            </Link>
            <div className="hero__heading">
              <h1 className="hero__title">Może Ryba</h1>
              <OpenSign />
            </div>
            <div className="hero__choose"><SelectLocal /></div>
            <h1 className="hero__title hero__title--small"> Fish & Chips, Dorsz Burgery, Krewetki, Sałatki </h1>
        </div>
    </div>
  );
}

export default Hero;