import React from 'react';
import './Loader.css';

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

    render(){
        return (
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }

}

export default Loader;
