import React from 'react';
import { connect } from 'react-redux';


class Summary extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        };

    }

    render(){
        return(
            <div>
                {/* {console.log(typeof(this.props.price))} */}
                 <h2>Podsumowanie</h2>
                 <p>Zamówienie: {Number(this.props.price).toFixed(2)} ZŁ</p>
                 <p>Koszt dostawy: {Number(this.props.deliveryPrice).toFixed(2)} ZŁ</p>
                 <p>Opakowanie: {Number(this.props.wrapPrice).toFixed(2)} ZŁ</p>
                 <div style={{display: "flex"}}>
                    <h3>Do zapłaty: </h3>
                    {this.props.usedCode.length === 0 ?
                    <h3>{Number(this.props.order.map(item=> item.amount*item.price).reduce((pv, cv) => pv + cv, 0) + Number(this.props.deliveryPrice) +Number(this.props.wrapPrice)).toFixed(2)} ZŁ</h3>
                    :
                    <h3>
                        <s> {Number(this.props.order.map(item=> item.amount*item.price).reduce((pv, cv) => pv + cv, 0) + Number(this.props.deliveryPrice) +Number(this.props.wrapPrice)).toFixed(2)} ZŁ </s>
                        {Number(this.props.finalPrice).toFixed(2)} ZŁ
                    </h3>
                    }
                </div>
            </div>
        )
    }

}

function mapStateToProps(state){
    return {
        usedCode: state.usedCode,
        order: state.order,
        price: state.price,
        deliveryPrice: state.deliveryPrice,
        wrapPrice: state.wrapPrice,
        finalPrice: state.finalPrice
    };
}

export default connect(mapStateToProps, null)(Summary);