import React from 'react';

const UserIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="298.104" height="298.103" viewBox="0 0 298.104 298.103">
  <path className={`${classNamePath || ""}`} id="user-circle" d="M149.051,8A149.051,149.051,0,1,0,298.1,157.051,149.026,149.026,0,0,0,149.051,8Zm0,57.7a52.889,52.889,0,1,1-52.889,52.889A52.89,52.89,0,0,1,149.051,65.7Zm0,206.749A115.171,115.171,0,0,1,61,231.457a67.009,67.009,0,0,1,59.2-35.941,14.706,14.706,0,0,1,4.267.661,79.575,79.575,0,0,0,24.581,4.147,79.273,79.273,0,0,0,24.581-4.147,14.705,14.705,0,0,1,4.267-.661,67.009,67.009,0,0,1,59.2,35.941A115.171,115.171,0,0,1,149.051,272.446Z" transform="translate(0 -8)"/>
</svg>
);

export default UserIcon;