import React from 'react';

class PromotionsRegulations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  render() {
    return (
      <div className="container__new">
        <h2>Regulamin promocji 2 za 1, 4 za 2 z kodem za zarejestrowanie konta.</h2>

        <div>
          <h3 style={{ fontWeight: '400' }}>1. Promocja trwa do odwołania. Promocja obowiązuje przy zamówieniu składanym przez stronę mozeryba.pl</h3>
          <h3 style={{ fontWeight: '400' }}>2. Promocja obowiązuje tylko z kodem otrzymanym na adres e-mail podany przy rejestracji konta na stronie mozeryba.pl. </h3>
          <h3 style={{ fontWeight: '400' }}>3. Promocje nie łączą się ze sobą. Na jedno zarejestrowane konto i na jedną osobę fizyczną przysługuje jeden kod promocyjny, do jednorazowego wykorzystania.</h3>
          <h3 style={{ fontWeight: '400' }}>4. Przy korzystaniu z promocji za założenie konta system lojalnościowy nie działa (zbieranie pieczątek). </h3>
          <h3 style={{ fontWeight: '400' }}>5. Pracownik restauracji może poprosić o weryfikację podanego przy rejestracji adresu email. </h3>
          <h3 style={{ fontWeight: '400' }}>6. Promocja jest dostępna wyłącznie na mozeryba.pl.</h3>
        </div>

        <h2>Regulamin promocji 3 za 2, 2 za 1, upust procentowy, bon od kwoty, darmowa dostawa.</h2>

        <div>
          <h3 style={{ fontWeight: '400' }}>1. Promocja trwa do odwołania. Promocja obowiązuje na miejscu lub na wynos oraz dowóz (należy przekazać ulotke kierowcy)</h3>
          <h3 style={{ fontWeight: '400' }}>2. Promocja obowiązuje tylko z ulotką (trzeba ją zabrać ze sobą i przekazać obsłudze na miejscu lub kierowcy). </h3>
          <h3 style={{ fontWeight: '400' }}>3. Promocje nie łączą się ze sobą. Jednej osobie na jedną ulotkę przysługuje maksymalnie 1 gratisowy zestaw Dużego Dorsza w Tempurze.</h3>
          <h3 style={{ fontWeight: '400' }}>4. Przy korzystaniu z ulotki promocyjnej system lojalnościowy nie działa (zbieranie pieczątek). </h3>
          <h3 style={{ fontWeight: '400' }}>5. Obsługa nie ma obowiązku informowania o promocjach które wynikają z ulotek promocyjnych.</h3>
          <h3 style={{ fontWeight: '400' }}>6. Jedna osoba może skorzystać maksymalnie raz z danej promocji nawet jeżeli będzie posiadała dwie ulotki. </h3>
          <h3 style={{ fontWeight: '400' }}>7. Kserowanych ulotek nie honorujemy</h3>
          <h3 style={{ fontWeight: '400' }}>8. Promocje nie są dostępne przez portale internetowe typu Ubereats, Glovo, Pyszne.pl, Głodny, Grupon. </h3>
        </div>

        <h2>Regulamin promocji Pieczątki.</h2>

        <div>
          <h3 style={{ fontWeight: '400' }}>1. Jedna pieczątka przysługuje za jedno zamówienie/paragon niezależnie od kwoty czy ilości dań. Jeżeli klient zamówił 3 dania główne i będzie miał jedną kartę to otrzyma jedną pieczątkę.</h3>
          <h3 style={{ fontWeight: '400' }}>2. Pieczątka przysługuje przy zamawianiu dań głównych / sałatek / burgerów. Za zamówienie samych dodatków pieczątek nie nabijamy.</h3>
          <h3 style={{ fontWeight: '400' }}>3. Rabat nalicza się po zgromadzeniu 5 pieczątek przy 6 zamówieniu. W dniu wykorzystania rabatu wydaje się pustą kartę lojalnościową.</h3>

        </div>

      </div>
    )
  }
}



export default PromotionsRegulations;
