import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateOrderData(data, realPrice) {
  let errors = {};

  if (Validator.isEmpty(data.paymentType)) {
    errors.paymentType = 'Wybierz metodę płatności';
  }

  if (Validator.isEmpty(data.realization)) {
    errors.realization = 'Wybierz formę odbioru zamówienia';
  }

  if (data.realization === "delivery") {

    if (data.order.length < 1) {
      errors.order = 'Twoje zamówienie jest puste';
    }
    else {
      if (data.usedCode.length < 1) {
        console.log(data);
        if (data.local_code === '5f51a6b211492756ceb12bb6') {
          if (realPrice - data.deliveryPrice < 40) {
            console.log(realPrice, data.deliveryPrice, 'różnica:', realPrice - data.deliveryPrice); //20
            errors.realPrice = 'Minimalna kwota zamówienia to 40 ZŁ';
          }
        }
        else {
          if (realPrice - data.deliveryPrice < 35) {
            console.log(realPrice, data.deliveryPrice, 'różnica:', realPrice - data.deliveryPrice); //20
            errors.realPrice = 'Minimalna kwota zamówienia to 35 ZŁ';
          }
        }
      }
      else {
        if (data.finalPrice - data.deliveryPrice < 35) {
          console.log(data.finalPrice, data.deliveryPrice, 'różnica:', data.finalPrice - data.deliveryPrice); //35
          errors.finalPrice = 'Minimalna kwota zamówienia promocyjnego to 35 ZŁ';
        }
      }
    }
    if (Validator.isEmpty(data.building_number)) {
      errors.building_number = 'Podaj numer budynku';
    }
    if (String(data.localizations.filter(item => item._id === data.local_code).map(item => item.deliveryZonesType)) === "multiple") {
      if (data.distance > 8999) {
        errors.distance = 'Podany adres jest poza zasięgiem dostawy';
      }
    }
    if (String(data.localizations.filter(item => item._id === data.local_code).map(item => item.deliveryZonesType)) === "single") {
      if (data.vicinity !== "Fordon") {
        errors.vicinity = 'Podany adres jest poza zasięgiem dostawy';
      }
    }
    if (Validator.isEmpty(data.name)) {
      errors.name = 'Podaj Imie';
    }
    if (!Validator.isAlpha(data.name, ['pl-PL']) && !Validator.isEmpty(data.name)) {
      errors.name = 'Pole Imię nie może zawierac znaków specjalnych';
    }
    if (Validator.isEmpty(data.surname)) {
      errors.surname = 'Podaj Nazwisko';
    }
    if (!Validator.isAlpha(data.surname, ['pl-PL']) && !Validator.isEmpty(data.surname)) {
      errors.surname = 'Pole Nazwisko nie może zawierac znaków specjalnych';
    }
    if (Validator.isEmpty(data.email)) {
      errors.email = 'Pole Email jest wymagane';
    }

    if (Validator.isEmail(data.email) === false && Validator.isEmpty(data.email)) {
      errors.email = 'Podany adres email jest niepoprawny';
    }
    if (Validator.isEmpty(data.phone)) {
      errors.phone = 'Pole Numer Telefonu jest wymagane';
    }

    if (!Validator.isNumeric(data.phone) && !Validator.isEmpty(data.phone)) {
      errors.phone = 'Podaj poprawny numer telefonu';
    }
    if (Validator.isEmpty(data.street)) {
      errors.street = 'Podaj ulicę';
    }
    if (!Validator.isAlphanumeric(data.local, ['pl-PL']) && !Validator.isEmpty(data.local)) {
      errors.local = 'Podaj poprawny numer mieszkania';
    }
    if (data.rodo === false) {
      errors.rodo = 'Zaakcecptowanie polityki jest wymagane!';
    }
  }
  if (data.realization === "pick-up") {
    if (data.order.length < 1) {
      errors.order = 'Twoje zamówienie jest puste';
    }
    if (Validator.isEmpty(data.name)) {
      errors.name = 'Podaj Imie';
    }
    if (!Validator.isAlpha(data.name, ['pl-PL']) && !Validator.isEmpty(data.name)) {
      errors.name = 'Pole Imię nie może zawierac znaków specjalnych';
    }

    if (Validator.isEmpty(data.surname)) {
      errors.surname = 'Podaj Nazwisko';
    }
    if (!Validator.isAlpha(data.surname, ['pl-PL']) && !Validator.isEmpty(data.surname)) {
      errors.surname = 'Pole Nazwisko nie może zawierac znaków specjalnych';
    }
    if (Validator.isEmpty(data.email)) {
      errors.email = 'Pole Email jest wymagane';
    }

    if (!Validator.isEmail(data.email) && Validator.isEmpty(data.email)) {
      errors.email = 'Podany adres email jest niepoprawny';
    }
    if (Validator.isEmpty(data.phone)) {
      errors.phone = 'Pole Numer Telefonu jest wymagane';
    }

    if (!Validator.isNumeric(data.phone) && !Validator.isEmpty(data.phone)) {
      errors.phone = 'Podaj poprawny numer telefonu';
    }
    if (data.rodo === false) {
      errors.rodo = 'Zaakcecptowanie polityki jest wymagane!';
    }

  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}
