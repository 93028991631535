import React from 'react';
import './OpenSign.css';
import { connect } from 'react-redux';
import { getLocalizations, changeLocalization } from '../../js/actions/index';
import Chevron from '../Accordion/Chevron';

function mapDispatchToProps(dispatch) {
  return {
    getLocalizations: () => dispatch(getLocalizations()),
    changeLocalization: id => dispatch(changeLocalization(id)),
  };
}

class SelectLocal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setActive: "",
      setHeight: "0px",
      setRotate: "accordion__icon",
      setOpacity: 0,
    };
    this.handleLocal = this.handleLocal.bind(this);
    this.content = React.createRef();

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  handleLocal(id) {
    this.setState({ localization: id });
    this.props.changeLocalization(id);
    this.toggleDropdown();
  }

  toggleDropdown() {
    this.setState({ setActive: (this.state.setActive === "" ? "active" : "") });
    this.setState({ setHeight: (this.state.setActive === "active" ? "0px" : `${this.content.current.scrollHeight}px`) });
    this.setState({ setOpacity: (this.state.setActive === "active" ? 0 : 1) });
    this.setState({ setRotate: (this.state.setActive === "active" ? "accordion__icon" : "accordion__icon rotate") });
  }

  componentDidMount() {
    this.props.getLocalizations();
  }

  render() {
    return (
      <div className="content__header">
        {this.props.localizations.length > 1 ?
          <div className="dropdownMenu">
            <button className={`dropdownMenu__button ${this.state.setActive}`} onClick={this.toggleDropdown}>
              {this.props.localizations.filter(item => item._id === this.props.activeLocalization).map(item => <span key={item._id}>{item.address}</span>)}
              <Chevron className={`${this.state.setRotate} dropdownMenu__icon`} width={20} height={20} />
            </button>
            <div ref={this.content} style={{ maxHeight: `${this.state.setHeight}`, opacity: `${this.state.setOpacity}` }} className="dropdownMenu__options">
              {this.props.localizations.map(item =>
                item.active === true ?
                  <div key={item._id} className="dropdownMenu__option" onClick={() => this.handleLocal(item._id)}>
                    {item.address}
                  </div>
                  :
                  null
              )}
            </div>

            {/* <form>
                    <div className="form-group" style={{marginBottom: "0px"}}>
                        <select name="local" onChange={this.handleLocal} value={this.props.activeLocalization}>
                            {this.props.localizations.map( localization =>
                                <option key={localization._id} value={localization._id}> {localization.name} - {localization.address}</option>
                            )}
                        </select>
                    </div>
                </form> */}
          </div>
          :
          " "
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    localizations: state.localizations,
    activeLocalization: state.activeLocalization
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocal);
