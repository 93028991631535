import React from 'react';

const LogoutIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="302.896" height="230.828" viewBox="0 0 302.896 230.828">
  <path className={`${classNamePath || ""}`} id="sign-out-alt" d="M298.7,189.6l-100.97,100.97c-9.015,9.015-24.642,2.7-24.642-10.217v-57.7H91.354A14.39,14.39,0,0,1,76.93,208.235v-57.7a14.39,14.39,0,0,1,14.424-14.424h81.738v-57.7c0-12.862,15.566-19.232,24.642-10.217L298.7,169.169A14.547,14.547,0,0,1,298.7,189.6ZM115.395,287.569V263.528a7.233,7.233,0,0,0-7.212-7.212H57.7a19.212,19.212,0,0,1-19.232-19.232V121.689A19.212,19.212,0,0,1,57.7,102.456h50.485a7.233,7.233,0,0,0,7.212-7.212V71.2a7.233,7.233,0,0,0-7.212-7.212H57.7A57.712,57.712,0,0,0,0,121.689V237.083a57.712,57.712,0,0,0,57.7,57.7h50.485A7.233,7.233,0,0,0,115.395,287.569Z" transform="translate(0 -63.979)"/>
</svg>);

export default LogoutIcon;