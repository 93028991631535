import React from 'react';
import './Accordion.css';
import Accordion from './Accordion';
import { connect } from 'react-redux';
import { getCategories, getMealsOptions, getMealsOptionsCategories } from '../../js/actions/index';
import Loader from '../Loader/Loader';

class CategoryList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            location: ""
        };

    }

    componentDidMount() {
        this.props.getCategories();
        this.props.getMealsOptionsCategories();
        this.props.getMealsOptions();

    }

    render(){
        if (this.props.loading) {
            return <div className="container__new" style={{textAlign: "center"}}>
                        <div><Loader /></div>
                    </div>
        }
        if (this.props.error) {
            return <div className="container__new" style={{ color: 'red' }}>ERROR: {this.props.error}</div>
        }
        return(
            <div className="container__new">
                <h2>Menu</h2>
                {this.props.categories.filter(item => item.local === this.props.activeLocalization).map(category => (
                    <Accordion key={category._id} id={category._id} name={category.name} image={category.image} />
                ))
                }
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCategories: () => dispatch(getCategories()),
        getMealsOptionsCategories: () => dispatch(getMealsOptionsCategories()),
        getMealsOptions: () => dispatch(getMealsOptions()),
    };
}

function mapStateToProps(state){
    return {
        categories: state.categories,
        loading: state.categoriesLoading,
        error: state.categoriesError,
        activeLocalization: state.activeLocalization
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);