import React from 'react';
import './Accordion.css';
import { connect } from 'react-redux';
import { addToCart, getOpenHours } from '../../js/actions/index';
import Alert from '../Alerts/Alert';

function mapDispatchToProps(dispatch) {
    return {
        addToCart: (item, options) => dispatch(addToCart(item, options)),
        getOpenHours: () => dispatch(getOpenHours()),
    };
}

const mapStateToProps = state => {
    return {
        mealsOptionsCategories: state.mealsOptionsCategories,
        mealsOptions: state.mealsOptions,
        loading: state.openhoursLoading,
        error: state.openhoursError,
        openhours: state.openhours,
        activeLocalization: state.activeLocalization,
    }
}


class ConnectedAccordionItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setActive: "",
            setHeight: "0px",
            setRotate: "accordion__icon",
            showOptions: false,
            showError: false,
            option: "",

        };

        this.handleCloseOptions = this.handleCloseOptions.bind(this);
        this.handleShowOptions = this.handleShowOptions.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleHideError = this.handleHideError.bind(this);
        this.onOptionChange = this.onOptionChange.bind(this);
        this.handleAddToCart = this.handleAddToCart.bind(this);
    }

    handleCloseOptions(){ this.setState({showOptions: false}, ()=>console.log(this.state)); };
    handleShowOptions(){ this.setState({showOptions: true}) };

    handleError(){ this.setState({showError: true}); };
    handleHideError(){ this.setState({showError: false}) };

    handleAddToCart(props, option){
        this.props.addToCart(props, option);
        this.handleCloseOptions();
    }

    onOptionChange(event) {
        //console.log(event.target.value);
        this.setState({
            option: event.target.value
        });

        this.handleHideError();
        }

        componentDidMount() {
            this.props.getOpenHours();
        }

render(){
    let date = new Date();
    let day = new Date().toLocaleString('en-us', {weekday:'long'}).toLowerCase();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    let nowTime = hour*60*60*1000 + minutes*60*1000;
    let open = this.props.openhours.filter(item => item.local === this.props.activeLocalization).map(item => item.days.filter(item => item.day === day).map(item => item.hours.open)[0]);
    let close = this.props.openhours.filter(item => item.local === this.props.activeLocalization).map(item => item.days.filter(item => item.day === day).map(item => item.hours.close)[0]);

    if (this.props.loading) {
      return(
        <div>
            Loading
        </div>
      )
    }
    if (this.props.error) {
        return <div></div>
    }
    return(
        <div className="accordion__item">
            <div className="accordion__photo_min"><img src={process.env.REACT_APP_API + this.props.image} alt="" className="items__image"/></div>
            <div className="accordion__data">
                <p className="accordion__item__title">{this.props.name}</p>
                <p className="accordion__desc">{this.props.desc}</p>
            </div>
            {close[0] === open[0] || nowTime < open[0] - 1*60*60*1000 || nowTime > close[0]
            ?
            <div className="accordion__price tooltip">{(this.props.price).toFixed(2)}
                <span className="price__currency">PLN</span>
                <span className="tooltiptext tooltip-left">Lokal zamknięty</span>
            </div>
            :
                this.props.mealsOptionsCategories.filter(meal=> this.props.id === meal.meal_id).length === 0
                ?
                <div className="accordion__price" onClick={() => {this.props.addToCart(this.props, [])}}>{(this.props.price).toFixed(2)} <span className="price__currency">PLN</span></div>
                :
                <div className="accordion__price" onClick={() => {this.handleShowOptions()}}>{(this.props.price).toFixed(2)} <span className="price__currency">PLN</span></div>
        }


        <div className="modal" style={{display: this.state.showOptions === true ? 'block' : 'none'}}>
            <div className="modal-main">
                <div>
                    <h2>Wybierz Sos</h2>
                </div>
                <div onChange={this.onOptionChange} style={{display: "flex", flexDirection: "column"}}>
                {this.props.mealsOptionsCategories.filter(meal=> this.props.id === meal.meal_id).map(category =>
                    this.props.mealsOptions.filter(options=> options.category_id === category._id).map(option=>
                        <label key={option._id} style={{padding: "5px 10px"}}>
                            <input
                            type="radio"
                            name={category._id}
                            value={option.name}
                            className="form-check-input"
                            />
                            <span style={{padding: "0px 10px"}}>{option.name}</span>
                        </label>
                    )
                )}
                </div>
                {this.state.showError === false ? '' : <Alert alertMessage={"Wybierz sos"} type="error" />}
                <button onClick={()=>{this.state.option.length < 1 ? this.handleError() : this.handleAddToCart(this.props, [this.state.option])}}>
                    Dodaj do koszyka
                </button>
            </div>
        </div>

        </div>
    );
}

}

const AccordionItem = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedAccordionItem);


export default AccordionItem;