import React from 'react';
import { connect } from 'react-redux';
import DeliveryIcon from '../Icons/Delivery';
import PickupIcon from '../Icons/Pickup';
import './ToggleButton.css';
import { realization } from '../../js/actions';
import AddressCalculate from './CheckoutComponents/AddressCalculate';


class Delivery extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            realization: "pick-up",
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    handleClick(type) {
        console.log(this.state.realization);
      }

    handleChange(event){
        if(event.target.checked === true){
            this.setState({realization: "delivery"})
            this.props.realization("delivery")
        }
        else{
            this.setState({realization: "pick-up"})
            this.props.realization("pick-up")
        }
    }

    render(){

        return(
        <div className="cart__delivery_options">
            <div className="cart__realization">
                <div className="switch">
                    <input id="cmn-toggle-1" className="cmn-toggle cmn-toggle-round" type="checkbox" onChange={this.handleChange}/>
                    <label htmlFor="cmn-toggle-1">
                        <div className="option-1">
                            <DeliveryIcon className="option__icon" classNamePath="choose__icon__path" />
                        </div>
                        <div className="option-2">
                            <PickupIcon className="option__icon" classNamePath="choose__icon__path" />
                        </div>
                    </label>
                </div>
                { this.state.realization === "pick-up" ? <div className="realization__name">Odbiór osobisty</div>: <div className="realization__name">Dostawa</div> }
            </div>
            { this.state.realization === "delivery" ? <div className=""> <AddressCalculate /> </div>: '' }
        </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return{
        realization: type => dispatch(realization(type))
    }
}

function mapStateToProps(state){
    return {
        realization: state.realization,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);