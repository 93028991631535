import React from 'react';
import './OpenSign.css';
import { connect } from 'react-redux';
import { getOpenHours } from '../../js/actions/index';

class OpenSign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "close",
      day: ''
    };

    this.today = this.today.bind(this);
  }

  today(data) {
    // let date = new Date();
    // let day = date.getDay();
    // let hour = date.getHours();
    // let minutes = date.getMinutes();

    console.log("test: ", data.filter(item => item.local === this.props.activeLocalization));

    // let nowTime = hour*60*60*1000 + minutes+60*1000;
    // let openTime = data.filter(item => item.local === this.props.activeLocalization).filter(item => item.days.dau === day)[0].hours.open;
    // let closeTime = data.filter(item => item.day_id === day)[0].hours.close;

    // if(closeTime<nowTime || nowTime<openTime){
    //   this.setState({type: "close"})
    // }
    // else{
    //   this.setState({type: "open"})
    // }
  }

  componentDidMount() {
    this.props.getOpenHours();
  }

  render() {
    let date = new Date();
    let day = new Date().toLocaleString('en-us', { weekday: 'long' }).toLowerCase();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    let nowTime = hour * 60 * 60 * 1000 + minutes * 60 * 1000;
    let open = this.props.openhours.filter(item => item.local === this.props.activeLocalization).map(item => item.days.filter(item => item.day === day).map(item => item.hours.open)[0]);
    let close = this.props.openhours.filter(item => item.local === this.props.activeLocalization).map(item => item.days.filter(item => item.day === day).map(item => item.hours.close)[0]);

    if (this.props.loading) {
      return (
        <div>
          <div className="open_sign_closed" >
            <div className="open_sign__inner_closed">
            </div>
          </div>
        </div>
      )
    }
    if (this.props.error) {
      return <div></div>
    }
    return (
      <div>
        {close[0] < nowTime || nowTime < open[0]
          ?
          <div className="open_sign_closed" >
            <div className="open_sign__inner_closed">
            </div>
          </div>
          :
          <div className="open_sign" >
            <div className="open_sign__inner">
            </div>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.openhoursLoading,
    error: state.openhoursError,
    openhours: state.openhours,
    activeLocalization: state.activeLocalization,
  };
}

export default connect(mapStateToProps, { getOpenHours })(OpenSign);
