import {
  DATA_LOADED, SIGNUP_SUCCESS,
  SIGNUP_ERROR, LOGIN_SUCCESS, LOGIN_ERROR,
  AUTH_SUCCESS, AUTH_ERROR, LOGOUT, CATEGORIES_LOADING, CATEGORIES_SUCCESS, CATEGORIES_ERROR,
  MENU_ITEMS_LOADED, ADD_TO_CART,
  CODES_LOADED, CODES_LOADED_ERR, DELIVERY_FORM_UPDATE, PICKUP_FORM_UPDATE, PAYMENT_CHANGE,
  REALIZATION_CHANGE, REALIZATION_TIME_CHANGE, REALIZATION_TIME_VALUE_CHANGE, AMOUNT_ADD, AMOUNT_DELETE, DELIVERY_DATA_UPDATE,
  GET_PAYMENT_METHODS, PLACE_CHANGE, COPY_ORDERS, GET_ORDER_HISTORY_LOADING, GET_ORDER_HISTORY_SUCCESS, GET_ORDER_HISTORY_ERROR,
  ORDER_FETCH_LOADING, ORDER_FETCH_SUCCESS, ORDER_FETCH_ERROR, UPDATE_ORDER, CHECK_STATUS, CHECK_STATUS_ERROR, GET_OPEN_HOURS_LOADING, GET_OPEN_HOURS_SUCCESS, GET_OPEN_HOURS_ERROR,
  CLEAR_ACTIVE_ORDER, GET_MEALS_OPTIONS_CATEGORIES, GET_MEALS_OPTIONS, CHANGE_LOCALIZATION,
  GET_LOCALIZATIONS_LOADING, GET_LOCALIZATIONS_SUCCESS, GET_LOCALIZATIONS_ERROR, GET_INFOS_LOADING, GET_INFOS_SUCCESS, GET_INFOS_ERROR, PROMOPRODUCT_DELETE
} from './../constants/action-types';
import axios from 'axios';


// export function getData() {
//     let url = `https://jsonplaceholder.typicode.com/users`;

//     return function (dispatch) {
//         axios.get(url)
//         .then((response) => dispatch({
//             type: DATA_LOADED,
//             payload: response.data
//         }))
//     }
// }

export const getCategories = () => dispatch => {
  let url = `${process.env.REACT_APP_API}categories`;

  dispatch({ type: CATEGORIES_LOADING });
  axios.get(url)
    .then((response) => dispatch({
      type: CATEGORIES_SUCCESS,
      payload: response.data
    }))
    .catch((err) => dispatch({
      type: CATEGORIES_ERROR,
      payload: err.response.data
    }))
}

export function getMenuItems() {
  let url = `${process.env.REACT_APP_API}meals`;

  return function (dispatch) {
    axios.get(url)
      .then((response) => dispatch({
        type: MENU_ITEMS_LOADED,
        payload: response.data
      }))
  }
}

export function copyOrder(orders, wrapPrice) {
  //console.log("WRRRAP", wrapPrice);
  return { type: COPY_ORDERS, payload: { order: orders, wrapPrice: wrapPrice } }
}

export function addUser(data) {
  let url = `${process.env.REACT_APP_API}users`;

  return function (dispatch) {
    return axios.post(url,
      {
        name: data.name,
        surname: data.surname,
        phone: data.phone,
        email: data.email,
        password: data.password
      })
      .then((response) => dispatch({
        type: SIGNUP_SUCCESS,
        payload: response.data
      }))
      .catch((err) => dispatch({
        type: SIGNUP_ERROR,
        payload: err.response.data
      }))
  }
}

export function loginUser(data) {
  let url = `${process.env.REACT_APP_API}user/login`;

  return dispatch => {
    return axios.post(url,
      {
        email: data.email,
        password: data.password
      })
      .then(
        user => {
          localStorage.setItem('user', JSON.stringify(user.data));
          //console.log('xx', user.data);
          dispatch(success(user.data));

        },
        error => {
          //console.log(error);
          dispatch(failure(error));
        }
      )
  }

  function success(user) { return { type: LOGIN_SUCCESS, payload: user } }
  function failure(err) { return { type: LOGIN_ERROR, payload: err.response.data } }

  // return function(dispatch) {
  //     return axios.post(url,
  //         {
  //             email: data.email,
  //             password: data.password
  //         })
  //         .then((response) => dispatch({
  //             type: LOGIN_SUCCESS,
  //             payload: response.data
  //         }))
  //         .catch((err) => dispatch({
  //             type: LOGIN_ERROR,
  //             payload: err.data
  //         }))
  //     }




  //         {
  //             email: data.email,
  //             password: bcrypt.hashSync(data.password, 10)
  //         })
  //         .then((response) => dispatch({
  //             type: SIGNUP_SUCCESS,
  //             payload: response.data.message
  //         }))
  //         .catch((err) => dispatch({
  //             type: SIGNUP_ERROR,
  //             payload: err.response.data
  //         }))
  // }

}

export function getAuth() {
  let user = JSON.parse(localStorage.getItem('user'));

  if (user) {
    return { type: AUTH_SUCCESS, payload: { loggedIn: true, user: user } }
  }
  else {
    return { type: AUTH_ERROR, payload: { loggedIn: false, user: {} } }
  }
}

export function logout() {
  localStorage.removeItem('user');
  return {
    type: LOGOUT,
    payload: { loggedIn: false, user: {} }
  }
}
export const getOrderHistory = (user) => dispatch => {
  let url = `${process.env.REACT_APP_API}orderhistory/${user}`;

  dispatch({ type: GET_ORDER_HISTORY_LOADING });
  axios.get(url)
    .then((response) => dispatch({
      type: GET_ORDER_HISTORY_SUCCESS,
      payload: response.data
    }))
    .catch((err) => dispatch({
      type: GET_ORDER_HISTORY_ERROR,
      payload: err.response.data
    }))

}

export function checkStatus(data) {
  //console.log("check status", data);
  let url = `${process.env.REACT_APP_API}orderstatus/${data}`;

  return function (dispatch) {
    axios.get(url)
      .then((response) => dispatch({
        type: CHECK_STATUS,
        payload: response.data.activeOrder.map(item => item._id),
      },
        localStorage.setItem('OrderId', response.data.activeOrder.map(item => item._id)[0])
      )).catch((err) => dispatch({
        type: CHECK_STATUS_ERROR,
        payload: err.response.data.message,
      }));
  }
}



//Cart actions
export function addToCart(item, option) {
  //console.log("ADD ITEM TO CART");
  return {
    type: ADD_TO_CART,
    payload: { amount: 1, price: item.price, order: item.name, image: item.image, _id: item.id, category_id: item.category, option: option }
  }
}

export function amountAdd(item, option) {
  //console.log('add item', item.name, item.category);

  return function (dispatch) {
    dispatch({
      type: AMOUNT_ADD,
      payload: { amount: 1, price: item.price, order: item.order, _id: item._id, category_id: item.category_id, option: option }
    })
  }
}
export function amountDelete(item) {
  return dispatch => {
    dispatch({
      type: AMOUNT_DELETE,
      payload: { amount: item.amount, price: item.price, order: item.order, _id: item._id, category_id: item.category_id }
    })
  }

}

//Promo actions
export function applyCode(code, orderPrice, deliveryPrice, wrapPrice, order, local) {
  console.log("APPLY CODE", code, orderPrice, deliveryPrice, wrapPrice, order, local);
  let url = `${process.env.REACT_APP_API}checkcode`;

  return function (dispatch) {
    axios.post(url,
      {
        code: code,
        orderPrice: orderPrice,
        deliveryPrice: deliveryPrice,
        wrapPrice: wrapPrice,
        order: order,
        local: local
      })
      .then((response) => {
        console.log("got: ", response.data);
        if (response.status === 201) {
          if (!response.data.product || order.filter(item => item.promoProduct).length > 0) {
            dispatch({
              type: CODES_LOADED,
              payload: { data: response.data, usedCode: code }
            })
          }
          else {
            dispatch({
              type: ADD_TO_CART,
              payload: {
                amount: 1,
                price: 0,
                order: response.data.product.name,
                image: response.data.product.image,
                _id: response.data.product.id,
                category_id: response.data.product.category,
                option: '',
                promoProduct: response.data.promoProduct,
              }
            })
          }
        }
        else if (response.status === 202) {
          dispatch({
            type: CODES_LOADED_ERR,
            payload: { message: response.data.message }
          })
        }
        return response;
      }).then(response => {
        //console.log('sec then: ', response);
        if (response.status === 201) {
          if (!response.data.product) {
            //console.log('END');
          }
          else {
            //console.log('GO VEGE');
            dispatch({
              type: CODES_LOADED,
              payload: { data: response.data, usedCode: code }
            });
          }
        }
        else if (response.status === 202) {
          dispatch({
            type: CODES_LOADED_ERR,
            payload: { message: response.data.message }
          })
        }
      })
      .catch((err) => {
        //console.log(err.response.data.message);
      })
  }
}

export function refreshCode(code, orderPrice, deliveryPrice, wrapPrice, order, local) {
  console.log("REFRESH CODE", code, orderPrice, deliveryPrice, wrapPrice, order, local);
  let url = `${process.env.REACT_APP_API}checkcode`;
  return function (dispatch) {
    axios.post(url,
      {
        code: code,
        orderPrice: orderPrice,
        deliveryPrice: deliveryPrice,
        wrapPrice: wrapPrice,
        order: order,
        local: local
      })
      .then((response) => {
        console.log("got: ", response.data);
        if (response.status === 201) {
          //console.log("got: ", order.filter(item => item.promoProduct).length);
          if (!response.data.product || order.filter(item => item.promoProduct).length > 0) {
            dispatch({
              type: CODES_LOADED,
              payload: { data: response.data, usedCode: code }
            })
          }
          else {
            //console.log("got: ", order.filter(item => item.promoProduct));
            dispatch(
              {
                type: ADD_TO_CART,
                payload: {
                  amount: 1,
                  price: 0,
                  order: response.data.product.name,
                  image: response.data.product.image,
                  _id: response.data.product.id,
                  category_id: response.data.product.category,
                  option: '',
                  promoProduct: response.data.promoProduct,
                }
              }
            );
          }
          dispatch({
            type: CODES_LOADED,
            payload: { data: response.data, usedCode: code }
          })
        }
        else if (response.status === 202) {
          dispatch({
            type: PROMOPRODUCT_DELETE,
            payload: { data: response.data }
          })
          //PROMOPRODUCT_DELETE
          //console.log(response.data.message);
        }
        return response;
      }).then(response => {
        //console.log('sec then: ', response);
        if (response.status === 201) {
          if (!response.data.product) {
            //console.log('END');
          }
          else {
            //console.log('GO VEGE');
            dispatch({
              type: CODES_LOADED,
              payload: { data: response.data, usedCode: code }
            });
          }
        }
        else if (response.status === 202) {
          //console.log(response.data.message);
        }
      })
      .catch((err) => {
        //console.log(err.response.data.message)
      })
  }
}
//Order actions

export function addOrder(order, realPrice) {
  //console.log("Order add action");
  let url = `${process.env.REACT_APP_API}orders`;

  let finalPrice;
  let orderStatus;

  if (order.paymentType === "p-gg") {
    orderStatus = "Przyjęte";
  }
  else {
    orderStatus = "Płatność...";
  }

  if (order.finalPrice === 0) {
    finalPrice = realPrice;
  }
  else {
    finalPrice = order.finalPrice;
  }
  axios.post(url,
    {
      order: order.order,

      user_id: order.user_id,
      name: order.name,
      surname: order.surname,
      email: order.email,
      phone: order.phone,
      cutlery: order.cutlery,

      finalPrice: finalPrice,
      price: order.price,
      deliveryPrice: order.deliveryPrice,
      distance: order.distance,
      paymentType: order.paymentType,
      paymentTypeName: order.paymentTypeName,

      building_number: order.building_number,
      street: order.street,
      locality: order.locality,
      postal_code: order.postal_code,
      local: order.local,

      status: orderStatus,
      payment_state: false,
      additionalInfo: order.additionalInfo,

      place: order.place,
      usedCode: order.usedCode,
      promoType: order.promoType,
      realization: order.realization,
      realizationTime: order.realizationTime,
      realizationTimeValue: order.realizationTimeValue,

      local_code: order.local_code,

      wrapPrice: order.wrapPrice

    })
    .then(function (response) {
      //console.log("im reachable");
      //console.log("me too", order.usedCode);

      axios.delete(`${process.env.REACT_APP_API}onetimecode/${order.usedCode}`)
      // .then((response) =>
      //     console.log(response)
      // )
      if (order.paymentType === "p-gg") {
        localStorage.setItem('OrderId', response.data.id);
        window.location.href = 'https://mozeryba.pl';
      }
      else {
        axios.post(`${process.env.REACT_APP_API}payment`, {
          token: localStorage.getItem('PayToken'),
          order: order,
          finalPrice: finalPrice,
          order_id: response.data.id
        })
          .then(res => {
            localStorage.setItem('OrderId', response.data.id);
            window.location.href = res.data.orderResponse.redirectUri;

          })
      }

    })
    .catch((err) => {
      //console.log("ERRR ",err);
    }
    );
}

//Realizzation action
export function place(payload) {
  return { type: PLACE_CHANGE, payload }
}

export function realization(payload) {
  return { type: REALIZATION_CHANGE, payload }
}

export function realizationTime(payload) {
  return {
    type: REALIZATION_TIME_CHANGE, payload
  }
}

export function realizationTimeValue(payload) {
  return { type: REALIZATION_TIME_VALUE_CHANGE, payload }
}

//Payment action
export function payment(type, name) {
  //console.log(type, name);
  return { type: PAYMENT_CHANGE, payload: { type: type, name: name } }
}

export function deliveryDataUpdate(payload) {
  console.log('DELIVERY_DATA', payload);
  return {
    type: DELIVERY_DATA_UPDATE,
    payload
  }
}

export function deliveryFormUpdate(payload) {
  return {
    type: DELIVERY_FORM_UPDATE,
    payload
  }
}

export function pickupFormUpdate(payload) {
  return {
    type: PICKUP_FORM_UPDATE,
    payload
  }
}


export function getPaymentToken() {
  let url = `${process.env.REACT_APP_API}paytoken`;

  return function (dispatch) {
    axios.post(url, {})
      .then((response) => {
        //console.log("TOKEN: ", response.status)
        localStorage.setItem('PayToken', response.data.PayToken)
      })
      .catch((err) => {
        console.log(err.response.data);
      })
  }
}

export function getPaymentMethods() {
  let url = `${process.env.REACT_APP_API}paymethods`;

  return function (dispatch) {
    axios.post(url, { token: localStorage.getItem('PayToken') })
      .then((response) => dispatch({
        type: GET_PAYMENT_METHODS,
        payload: response.data
      }))
      .catch((err) => console.log(err.response.data))
  }

}

export const getOrder = order => dispatch => {
  let url = `${process.env.REACT_APP_API}orders/${order}`;

  dispatch({ type: ORDER_FETCH_LOADING });

  axios.get(url)
    .then((response) => dispatch({
      type: ORDER_FETCH_SUCCESS,
      payload: response.data.orders
    }))
    .catch((err) => dispatch({
      type: ORDER_FETCH_ERROR,
      payload: err.response.data.message
    }))
}

export function updateOrder(order) {
  return {
    type: UPDATE_ORDER,
    payload: order
  }
}

export const getOpenHours = () => dispatch => {
  let url = `${process.env.REACT_APP_API}openhours`;

  dispatch({ type: GET_OPEN_HOURS_LOADING });
  axios.get(url)
    .then((response) => dispatch({
      type: GET_OPEN_HOURS_SUCCESS,
      payload: response.data
    }))
    .catch((err) => dispatch({
      type: GET_OPEN_HOURS_ERROR,
      payload: err.response.data
    }))

}

export function clearActiveOrder() {

  return {
    type: CLEAR_ACTIVE_ORDER,
    payload: null
  }
}

export function getMealsOptionsCategories() {
  let url = `${process.env.REACT_APP_API}meals-options-cat`;

  return function (dispatch) {
    axios.get(url)
      .then((response) => dispatch({
        type: GET_MEALS_OPTIONS_CATEGORIES,
        payload: response.data
      }))
  }
}

export function getMealsOptions() {
  let url = `${process.env.REACT_APP_API}meals-options`;

  return function (dispatch) {
    axios.get(url)
      .then((response) => dispatch({
        type: GET_MEALS_OPTIONS,
        payload: response.data
      }))
  }
}

//Locations management functions
export const getLocalizations = () => dispatch => {
  let url = `${process.env.REACT_APP_API}localizations`;

  dispatch({ type: GET_LOCALIZATIONS_LOADING });
  axios.get(url)
    .then((response) => dispatch({
      type: GET_LOCALIZATIONS_SUCCESS,
      payload: response.data
    }))
    .catch((err) => dispatch({
      type: GET_LOCALIZATIONS_ERROR,
      payload: err.response.data
    }))
}

export const changeLocalization = (id) => {
  localStorage.setItem('activeLocal', JSON.stringify(id))
  return {
    type: CHANGE_LOCALIZATION,
    payload: { id: id }
  }
}

//Info management
export const getInfos = () => dispatch => {
  let url = `${process.env.REACT_APP_API}infos`;

  dispatch({ type: GET_INFOS_LOADING });
  axios.get(url)
    .then((response) => dispatch({
      type: GET_INFOS_SUCCESS,
      payload: response.data
    }))
    .catch((err) => dispatch({
      type: GET_INFOS_ERROR,
      payload: err.response.data
    }))
}
