import React from 'react';

const LoginIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="307.719" height="235.623" viewBox="0 0 307.719 235.623">
  <path className={`${classNamePath || ""}`} id="sign-in-alt" d="M250.022,294.789H199.536a7.233,7.233,0,0,1-7.212-7.212V263.536a7.233,7.233,0,0,1,7.212-7.212h50.485a19.212,19.212,0,0,0,19.232-19.232V121.7a19.212,19.212,0,0,0-19.232-19.232H199.536a7.233,7.233,0,0,1-7.212-7.212V71.212A7.233,7.233,0,0,1,199.536,64h50.485a57.712,57.712,0,0,1,57.7,57.7V237.092A57.712,57.712,0,0,1,250.022,294.789Zm-28.248-120.8L120.8,73.015c-9.015-9.015-24.642-2.7-24.642,10.217v57.7H14.424A14.39,14.39,0,0,0,0,155.354v57.7a14.39,14.39,0,0,0,14.424,14.424H96.162v57.7c0,12.922,15.626,19.232,24.642,10.217l100.97-100.97A14.547,14.547,0,0,0,221.774,173.985Z" transform="translate(0 -64)"/>
</svg>);

export default LoginIcon;