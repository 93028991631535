import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateLoginData(data){
    let errors = {};

    if(Validator.isEmpty(data.email)) {
        errors.email = 'Pole email jest wymagane!';
    }
    if(!Validator.isEmail(data.email) && !Validator.isEmpty(data.email)) {
        errors.email = 'Podaj prawidłowy adres email!';
    }

    if (Validator.isEmpty(data.password)) {
        errors.password = 'Pole hasło jest wymagane!';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
}
