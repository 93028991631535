import React from 'react';
import './SocialIcon.css';

function SocialIcon(props) {
     // props: link, icon, color
  return (
    <a href={props.link} className="social_icon" rel="noopener noreferrer" target="_blank" style={{backgroundColor: props.color}} >
        <img className="social_icon__image" src={require(`../../images/${props.icon}.png`)} alt="Facebook" />
    </a>
  );
}

export default SocialIcon;