import React from 'react';

const CheckIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="307.719" height="229.469" viewBox="0 0 307.719 229.469">
  <path className={`${classNamePath || ""}`} id="check" d="M104.515,290.061,4.506,190.052a15.387,15.387,0,0,1,0-21.759l21.758-21.759a15.385,15.385,0,0,1,21.759,0l67.371,67.37,144.3-144.3a15.387,15.387,0,0,1,21.759,0l21.759,21.759a15.386,15.386,0,0,1,0,21.759L126.274,290.062a15.386,15.386,0,0,1-21.759,0Z" transform="translate(0 -65.098)"/>
</svg>
);

export default CheckIcon;