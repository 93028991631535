import React from "react";
import validateStatusData from './statusValidate';
import { connect } from "react-redux";
import { checkStatus } from "../../js/actions/index";
import PhoneIcon from "../Icons/Phone";
import Alert from "../Alerts/Alert";
import isEmpty from 'lodash/isEmpty';

function mapDispatchToProps(dispatch) {
  return {
    checkStatus: article => dispatch(checkStatus(article))
  };
}

function mapStateToProps(state){
  return {
      user: state.user
  }
}

class StatusForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      errors: {},
      isLoading: false,
      alert: {},
      message: 'Brak zamówienia',
      endpoint: process.env.REACT_APP_API_SOCKET
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    //this.sendData = this.sendData.bind(this);
    this.first = this.first.bind(this);
  }

  first(v){
    return v[Object.keys(v)[0]];
  };

  isValid(){
    const { errors, isValid } = validateStatusData(this.state);
    console.log('check');
    if(!isValid){
        this.setState({ errors });
    }

    return isValid;
  }

  // sendData = () => {
  //   this.props.parentCallback("Hey Popsie, How’s it going?");
  //   }



  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    console.log(this.state.phone)
  }

  handleSubmit(event) {
    event.preventDefault();

    if(this.isValid()){
        this.setState({ errors: {}, isLoading: true });
        this.props.checkStatus(this.state.phone);
        //this.sendData();
    }
  }

  render() {
    const { phone, errors } = this.state;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div>
            <div className="form__group__checkout">
              <input
                  placeholder="Telefon"
                  className="form__control__checkout"
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={this.handleChange}
                  error={errors.phone}
                  onBlur={this.handleUpdate}

              />
              <div className="cart__icon__box">
                  <PhoneIcon className="cart__icon" classNamePath="modal__close_icon_path"/>
              </div>

              {isEmpty(this.state.errors) ? '' : <Alert alertMessage={this.first(this.state.errors)} type="error" />}
            </div>
          </div>
          <button type="submit">Sprawdź status</button>
        </form>
      </div>
    );
  }
}

const Form = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusForm);

export default Form;