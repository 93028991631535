import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validatePromocode(data){
    let errors = {};

    if(Validator.isEmpty(data.promocode)) {
        errors.promocode = 'Zły kod!';
    }

    if(!Validator.isAlphanumeric(data.promocode) && Validator.isEmpty(data.promocode)) {
        errors.promocode = 'Zły kod!';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
}