
export const DATA_LOADED = "DATA_LOADED";


//Signup actions
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";


//Login actions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGOUT = "LOGOUT";

//Authentication actions
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";

//Menu actions
export const CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const CATEGORIES_SUCCESS = "CATEGORIES_SUCCESS";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";


export const MENU_ITEMS_LOADED = "MENU_ITEMS_LOADED";

//Cart actions
export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";

//Order actions
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_ERROR = "ORDER_ERROR";

//Promoactions
export const CODES_LOADED = "CODES_LOADED";
export const CODES_LOADED_ERR = "CODES_LOADED_ERR";

//Realization type
export const REALIZATION_CHANGE = "REALIZATION_CHANGE";
export const REALIZATION_TIME_CHANGE = "REALIZATION_TIME_CHANGE";
export const REALIZATION_TIME_VALUE_CHANGE = "REALIZATION_TIME_VALUE_CHANGE";

//Payment type
export const PAYMENT_CHANGE = "PAYMENT_CHANGE";

export const AMOUNT_ADD = "AMOUNT_ADD";
export const AMOUNT_DELETE = "AMOUNT_DELETE";

export const DELIVERY_DATA_UPDATE = "DELIVERY_DATA_UPDATE";
export const DELIVERY_FORM_UPDATE = "DELIVERY_FORM_UPDATE";
export const PICKUP_FORM_UPDATE = "PICKUP_FORM_UPDATE";

export const GET_PAYMENT_TOKEN = "GET_PAYMENT_TOKEN";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";

export const SET_ORDER_ID = "SET_ORDER_ID";

export const ORDER_FETCH_LOADING = "ORDER_FETCH_LOADING";
export const ORDER_FETCH_SUCCESS = "ORDER_FETCH_SUCCESS";
export const ORDER_FETCH_ERROR = "ORDER_FETCH_ERROR";

export const UPDATE_ORDER = "UPDATE_ORDER";

export const CHECK_STATUS = "CHECK_STATUS";
export const CHECK_STATUS_ERROR = "CHECK_STATUS_ERROR";

export const LOAD_OPEN_HOURS = "LOAD_OPEN_HOURS";

export const CLEAR_ACTIVE_ORDER = "CLEAR_ACTIVE_ORDER";

export const PLACE_CHANGE = "PLACE_CHANGE";

export const GET_ORDER_HISTORY_LOADING = "GET_ORDER_HISTORY_LOADING";
export const GET_ORDER_HISTORY_SUCCESS = "GET_ORDER_HISTORY_SUCCESS";
export const GET_ORDER_HISTORY_ERROR = "GET_ORDER_HISTORY_ERROR";

export const COPY_ORDERS = "COPY_ORDERS";

export const GET_MEALS_OPTIONS_CATEGORIES = "GET_MEALS_OPTIONS_CATEGORIES";
export const GET_MEALS_OPTIONS = "GET_MEALS_OPTIONS";

export const CHANGE_LOCALIZATION = "CHANGE_LOCALIZATION";

export const GET_LOCALIZATIONS_SUCCESS = "GET_LOCALIZATIONS_SUCCESS";
export const GET_LOCALIZATIONS_LOADING = "GET_LOCALIZATIONS_LOADING";
export const GET_LOCALIZATIONS_ERROR = "GET_LOCALIZATIONS_ERROR";

export const GET_INFOS_LOADING = "GET_INFOS_LOADING";
export const GET_INFOS_SUCCESS = "GET_INFOS_SUCCESS";
export const GET_INFOS_ERROR = "GET_INFOS_ERROR";

export const GET_OPEN_HOURS_LOADING = "GET_OPEN_HOURS_LOADING";
export const GET_OPEN_HOURS_SUCCESS = "GET_OPEN_HOURS_SUCCES";
export const GET_OPEN_HOURS_ERROR = "GET_OPEN_HOURS_ERROR";

export const PROMOPRODUCT_DELETE = "PROMOPRODUCT_DELETE";