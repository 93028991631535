import React from 'react';

const CartIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="269.254" height="307.719" viewBox="0 0 269.254 307.719">
  <path className={`${classNamePath || ""}`} id="shopping-bag" d="M211.557,96.162V76.93a76.93,76.93,0,1,0-153.859,0V96.162H0V259.638a48.081,48.081,0,0,0,48.081,48.081H221.173a48.081,48.081,0,0,0,48.081-48.081V96.162ZM96.162,76.93a38.465,38.465,0,1,1,76.93,0V96.162H96.162Zm96.162,72.122a14.424,14.424,0,1,1,14.424-14.424A14.424,14.424,0,0,1,192.324,149.051Zm-115.395,0a14.424,14.424,0,1,1,14.424-14.424A14.424,14.424,0,0,1,76.93,149.051Z"/>
</svg>);

export default CartIcon;