import React from 'react';
import './OrderHistory.css';
import Chevron from '../Accordion/Chevron';
// import AccordionItem from './AccordionItem.js';
import { connect } from 'react-redux';
import ArrowClockIcon from '../Icons/ArrowClock';
import { getOrderHistory, copyOrder } from '../../js/actions/index';

class OrderHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setActive: "",
            setHeight: "0px",
            setRotate: "accordion__icon"
        };

        this.content = React.createRef();
        this.copyOrder = this.copyOrder.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));

        if(user){
            this.props.getOrderHistory(user.user);
        }
        else{

        }
    }

    copyOrder(order, wrapPrice){
        this.props.copyOrder(order, wrapPrice);
    }

    toggleAccordion(){
        this.setState({setActive: (this.state.setActive === "" ? "active" : "")});
        this.setState({setHeight: (this.state.setActive === "active" ? "0px" : `${this.content.current.scrollHeight}px`)});
        this.setState({setRotate: (this.state.setActive === "active" ? "accordion__icon" : "accordion__icon rotate")});
    }

    render(){
        if (this.props.loading) {
            return <div className="container__new">
            <div key={this.props.id} className="accordion__section_sec">
                <button className={`accordion_sec ${this.state.setActive}`} onClick={this.toggleAccordion}>
                    <div className="accordion__photo">
                        <ArrowClockIcon className="order__history__icon" classNamePath="order__history__icon_path"/>
                    </div>
                    <div className="accordion__data_sec">
                        <p className="accordion__title"> Twoje ostatnie zamówienia</p>
                    </div>
                    <Chevron className={`${this.state.setRotate}`} width={28} height={28} fill={"#777"} />
                </button>

                <div ref={this.content} style={{ maxHeight: `${this.state.setHeight}` }} className="accordion__content">
                    <div className="accordion__item_sec">
                        <div> Brak ostatnich zamówień </div>
                    </div>
                </div>
            </div>
        </div>
        }
        if (this.props.error) {
            return <div className="container__new" style={{ color: 'red' }}>ERROR: {this.props.error}</div>
        }
        return(
            <div className="container__new">
                <div key={this.props.id} className="accordion__section_sec">
                    <button className={`accordion_sec ${this.state.setActive}`} onClick={this.toggleAccordion}>
                        <div className="accordion__photo">
                            <ArrowClockIcon className="order__history__icon" classNamePath="order__history__icon_path"/>
                        </div>
                        <div className="accordion__data_sec">
                            <p className="accordion__title"> Twoje ostatnie zamówienia</p>
                        </div>
                        <Chevron className={`${this.state.setRotate}`} width={28} height={28} fill={"#777"} />
                    </button>

                    <div ref={this.content} style={{ maxHeight: `${this.state.setHeight}` }} className="accordion__content">

        {this.props.historyOrders.length<1 ? <div className="accordion__item_sec"><div> Brak ostatnich zamówień </div></div>
        :
        this.props.historyOrders.sort((a, b) => (a.orderNumber < b.orderNumber) ? 1 : -1)
        .slice(0, 5)
        .map(item => <div key={item._id} className="accordion__item_sec">
            <div className="accordion__item_data_number">#{item.orderNumber}</div>
            <div className="accordion__item_data_list">
                {item.order.map((meal, i) => (<span key={meal._id}>{meal.order}{i < item.order.length-1 ? ", " : " "} </span>))}
            </div>
            <button onClick={()=>this.copyOrder(item.order, item.wrapPrice)} className="accordion__item_data_button">Dodaj</button>
            {/* {console.log(item)} */}
        </div>)}

                    </div>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
      getOrderHistory: user => dispatch(getOrderHistory(user)),
      copyOrder: (order, wrapPrice) => dispatch(copyOrder(order, wrapPrice)),

    };
  }

function mapStateToProps(state){
    return {

        historyOrders: state.historyOrders,
        loading: state.historyOrdersLoading,
        error: state.historyOrdersError
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);