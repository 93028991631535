import React from 'react';
import axios from 'axios';
import PayuLogo from '../../../images/payulogo.png';
import { connect } from 'react-redux';
import './Checkout.css';
import { payment } from '../../../js/actions';
import Loader from '../../Loader/Loader';

function mapDispatchToProps(dispatch) {
    return {
      payment: (type, name) => dispatch(payment(type, name))
    };
  }

class Payment extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            payment: "",
            paymentName: "",
            paymentMethods: [],
        };
    }

    handleClick(type, name) {
        this.setState({ payment: type, paymentName: name});
        //console.log(this.state.payment, this.state.paymentName);
        this.props.payment(type, name);
      }

    componentDidMount(){
        axios.post(`${process.env.REACT_APP_API}paymethods`, {token: localStorage.getItem('PayToken')})
            .then(res => {
                const paymentMethods = res.data;
                this.setState({paymentMethods: paymentMethods});
            })
    }

    render(){
        return(
            <div>
                <div className="payment__title"><h2>Forma płatności</h2><img className="payment__logo" src={PayuLogo} alt="PayU"></img></div>
                {this.state.paymentMethods.payMethods
                    ? <div className="payment__box choose__container payments">
                        {this.state.paymentMethods.payMethods.payByLinks.filter(item => item.status === "ENABLED").length < 1 ?
                        <div>Płatności są tymczasowo niedostępne</div>
                        :
                        this.props.realizationx === "delivery"
                            ?
                            this.state.paymentMethods.payMethods.payByLinks.filter(item => item.status === "ENABLED").map(method =>
                                <div className="choose__button" key={method.value} onClick={()=>this.handleClick(method.value, method.name)}><img src={method.brandImageUrl} alt={method.name}/> {method.name}</div>
                            )
                            :
                            <>
                                <div className="choose__button" key="p-gg" onClick={()=>this.handleClick("p-gg", "Gotówka")}> Zapłać przy odbiorze </div>
                                {this.state.paymentMethods.payMethods.payByLinks.filter(item => item.status === "ENABLED").map(method =>
                                    <div className="choose__button" key={method.value} onClick={()=>this.handleClick(method.value, method.name)}><img src={method.brandImageUrl} alt={method.name}/> {method.name}</div>
                                )}
                            </>

                        }
                    </div>
                    : <div style={{textAlign: "center"}}> <Loader /></div>}
            </div>
        )
    }

}

function mapStateToProps(state){
    return {
        realizationx: state.realization,
        paymentMethods: state.paymentMethods
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Payment);