import React from 'react';
import { connect } from 'react-redux';
import './Checkout.css';
import ArrowIcon from '../../Icons/Arrow';
import ArrowClockIcon from '../../Icons/ArrowClock';
import StopwatchIcon from '../../Icons/Stoper';
import { realizationTime, realizationTimeValue } from '../../../js/actions';

class RealizationTime extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            realizationTime: "",
            time: 39600000,
            selectTime: false,
            timeMin: 39600000,
            timeMax: 75600000,
            timeSkip: 15,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(time, rstate) {
        if(time === "planned" && rstate === "now"){
            this.setState({selectTime: true});
            this.setState({ realizationTime: time});
            this.props.realizationTimeValue(this.state.time);
            this.props.realizationTime(time);
        }
        else if(time === "now" && rstate === "planned"){
            this.setState({selectTime: false});
            this.setState({ realizationTime: ""});
            this.props.realizationTimeValue(0);
            this.props.realizationTime(time);
        }
    }

    msToHours(time){
        let hrs = Math.floor(time / 3600000);

        return hrs;
    }

    msToMinutes(time){
        let toMinutes = time % 3600000;
        let mins = Math.floor(toMinutes / 60000);

        if(mins<10){
            return "0"+mins;
        }
        else{
            return mins;
        }
    }

    handlePlusHour(){
        if(this.state.time + 3600000 > this.state.timeMax){
            this.setState({time: this.state.timeMin});
            this.props.realizationTimeValue(this.state.timeMin);
        }
        else{
            this.setState({time: this.state.time + 3600000});
            this.props.realizationTimeValue(this.state.time + 3600000);
        }
    }

    handleMinusHour(){
        if(this.state.time - 3600000 < this.state.timeMin){
            this.setState({time: this.state.timeMax});
            this.props.realizationTimeValue(this.state.timeMax);
        }
        else{
            this.setState({time: this.state.time - 3600000});
            this.props.realizationTimeValue(this.state.time - 3600000);
        }
    }

    handlePlusMinutes(){
        if(this.state.time + 60000*this.state.timeSkip > this.state.timeMax){
            this.setState({time: this.state.timeMin});
            this.props.realizationTimeValue(this.state.timeMin);
        }
        else{
            this.setState({time: this.state.time + 60000*this.state.timeSkip});
            this.props.realizationTimeValue(this.state.time + 60000*this.state.timeSkip);
        }
    }

    handleMinusMinutes(){
        if(this.state.time - 60000*this.state.timeSkip < this.state.timeMin){
            this.setState({time: this.state.timeMax});
            this.props.realizationTimeValue(this.state.timeMax);
        }
        else{
            this.setState({time: this.state.time - 60000*this.state.timeSkip});
            this.props.realizationTimeValue(this.state.time - 60000*this.state.timeSkip);
        }
    }

    // componentDidMount(){

    // }

    render(){
        return(
            <div>
                <h2>Czas Realizacji</h2>
                    <div className="choose__container time">
                        <div className={this.props.realizationx==="now" ? "choose__button checked" : "choose__button"} onClick={()=>this.handleClick("now", this.props.realizationx)}><div><div><StopwatchIcon className="choose__icon" classNamePath="choose__icon_path"/></div><div>Najszybciej</div></div></div>
                        <div className={this.props.realizationx==="planned" ? "choose__button checked" : "choose__button"} onClick={()=>this.handleClick("planned", this.props.realizationx)}>
                            {this.state.selectTime === true
                                ?   <div className="selectTime__button">
                                        <div className="selectTime__param">
                                            <div onClick={()=>this.handlePlusHour()} className="selectTime__arrow_container"><ArrowIcon className="selectTime__arrow_up" classNamePath="selectTime__arrow_path" /></div>
                                            <div>{this.msToHours(this.state.time)}</div>
                                            <div onClick={()=>this.handleMinusHour()} className="selectTime__arrow_container"><ArrowIcon className="selectTime__arrow_down" classNamePath="selectTime__arrow_path" /></div>
                                        </div>
                                        <div className="selectTime__separator">
                                            :
                                        </div>
                                        <div className="selectTime__param">
                                            <div onClick={()=>this.handlePlusMinutes()} className="selectTime__arrow_container"><ArrowIcon className="selectTime__arrow_up" classNamePath="selectTime__arrow_path" /></div>
                                            <div>{this.msToMinutes(this.state.time)}</div>
                                            <div onClick={()=>this.handleMinusMinutes()} className="selectTime__arrow_container"><ArrowIcon className="selectTime__arrow_down" classNamePath="selectTime__arrow_path" /></div>
                                        </div>
                                    </div>
                                : <div><div><ArrowClockIcon className="choose__icon" classNamePath="choose__icon_path"/></div><div style={{lineHeight: "20px"}}>Określona godzina</div></div>}
                        </div>
                    </div>
            </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return{
        realizationTime: type => dispatch(realizationTime(type)),
        realizationTimeValue: type => dispatch(realizationTimeValue(type))
    }
  }

function mapStateToProps(state){
    return {
        realizationx: state.realizationTime,
        realizationTime: state.realizationTimeValue
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RealizationTime);
