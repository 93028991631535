import React from 'react';

class Regulations extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    render(){
        return(
            <div className="container__new">
                <h2>Regulamin Restauracji</h2>

                <h3>1. Postanowienia wstępne</h3>
                <p> 1. Restauracja MOŻE RYBA, dostępny pod adresem internetowym mozeryba.pl, prowadzony jest przez ŁUKASZ OLSZANIECKI, prowadzącego działalność gospodarczą pod firmą MOŻE RYBA ŁUKASZ OLSZANIECKI, wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej (CEIDG) prowadzonej przez ministra właściwego ds. gospodarki, NIP 554-24-60-605, REGON 368654707 </p>

                <h3>2. Definicje </h3>
                <p>1. Konsument - osoba fizyczna zawierająca ze Sprzedawcą umowę w ramach strony internetowej Restauracji, której przedmiot nie jest związany bezpośrednio z jej działalnością gospodarczą lub zawodową.</p>
                <p>2. Sprzedawca lub Restauracja- MOŻE RYBA ŁUKASZ OLSZANIECKI z siedzibą w Bydgoszczy przy ul. Gdańska 39, kod pocztowy 85-005, NIP 554-24-60-605, REGON 368654707</p>
                <p>3. Klient - każdy podmiot dokonujący zakupów za pośrednictwem strony internetowej Restauracji.</p>
                <p>4. Przedsiębiorca - osoba fizyczna, osoba prawna i jednostka organizacyjna niebędąca osobą prawną, której odrębna ustawa przyznaje zdolność prawną, wykonująca we własnym imieniu działalność gospodarczą, która korzysta ze Restauracji. </p>
                <p>5. Strona internetowa Restauracji – witryna internetowa dostępna pod adresem mozeryba.pl</p>
                <p>6. Umowa zawarta na odległość - umowa zawarta z Klientem w ramach zorganizowanego systemu zawierania umów na odległość (w ramach Restauracji), bez jednoczesnej fizycznej obecności stron, z wyłącznym wykorzystaniem jednego lub większej liczby środków porozumiewania się na odległość do chwili zawarcia umowy włącznie.</p>
                <p>7. Regulamin - niniejszy regulamin.</p>
                <p>8. Zamówienie - oświadczenie woli Klienta składane za pomocą Formularza Zamówienia i zmierzające bezpośrednio do zawarcia Umowy Sprzedaży Produktu lub Produktów ze Sprzedawcą.</p>
                <p>9. Konto - konto klienta w strony internetowej Restauracji, są w nim gromadzone są dane podane przez Klienta oraz informacje o złożonych przez niego Zamówieniach.</p>
                <p>10. Formularz rejestracji - formularz dostępny na stronie internetowej Restauracji, umożliwiający utworzenie Konta.</p>
                <p>11. Formularz zamówienia - interaktywny formularz dostępny na stronie internetowej Restauracji umożliwiający złożenie Zamówienia, w szczególności poprzez dodanie Produktów do Koszyka oraz określenie warunków Umowy Sprzedaży, w tym sposobu dostawy i płatności. 12. Koszyk – element oprogramowania strony internetowej Restauracji, w którym widoczne są wybrane przez Klienta Produkty do zakupu, a także istnieje możliwość ustalenia i modyfikacji danych Zamówienia, w szczególności ilości produktów. </p>
                <p>13. Produkt - oznacza posiłki i napoje przedstawiane każdorazowo przez Restaurację w ramach oferty dostępnej na stronie internetowej. 14. Umowa Sprzedaży - umowa sprzedaży Produktu zawierana albo zawarta między Klientem a Sprzedawcą za pośrednictwem strony internetowej Restauracji. Przez Umowę Sprzedaży rozumie się też - stosowanie do cech Produktu - umowę o świadczenie usług i umowę o dzieło. </p>

                <h3>3. Kontakt z Restauracją </h3>
                <p>1. Adres e-mail Restauracji: info@mozeryba.pl</p>
                <p>2. Numer telefonu Restauracji: 796 215 484</p>
                <p>3. Klient może porozumiewać się z Restauracją za pomocą adresów i numerów telefonów podanych w niniejszym paragrafie.</p>
                <p>4. Klient może porozumieć się telefonicznie z Restauracją w godzinach działania lokalu</p>

                <h3>4. Wymagania techniczne </h3>
                <p>Do korzystania ze strony internetowej Restauracji w tym przeglądania dań oraz złożenia zamówienia, niezbędne są: </p>
                <p>a. urządzenie końcowe z dostępem do sieci Internet i przeglądarką internetową, </p>
                <p>b. aktywne konto poczty elektronicznej (e-mail), </p>
                <p>c. włączona obsługa plików cookies</p>

                <h3>5. Informacje ogólne </h3>
                <p>1. Restauracja w najszerszym dopuszczalnym przez prawo zakresie nie ponosi odpowiedzialności za zakłócenia w tym przerwy w funkcjonowaniu strony internetowej Restauracji spowodowane siłą wyższą, niedozwolonym działaniem osób trzecich lub niekompatybilnością stronie internetowej Restauracji z infrastrukturą techniczną Klienta.</p>
                <p>2. Przeglądanie asortymentu na stronie internetowej Restauracji nie wymaga zakładania Konta. Składanie zamówień przez Klienta na Produkty znajdujące się w asortymencie Restauracji możliwe jest albo po założeniu Konta zgodnie z postanowieniami § 6 Regulaminu albo przez podanie niezbędnych danych osobowych i adresowych umożliwiających realizację Zamówienia bez zakładania Konta. </p>
                <p>3.  Ceny podane stronie internetowej Restauracji są podane w polskich złotych i są cenami brutto (uwzględniają podatek VAT). </p>
                <p>4. Na końcową (ostateczną) kwotę do zapłaty przez Klienta składa się cena za Produkt oraz koszt dostawy (w tym opłaty za transport, dostarczenie i usługi pocztowe), o której Klient jest informowany na stronie internetowej Restauracji w trakcie składania Zamówienia, w tym także w chwili wyrażenia woli związania się Umową Sprzedaży. </p>
                <p>5. W przypadku Umowy obejmującej prenumeratę lub świadczenie usług na czas nieoznaczony końcową (ostateczną) ceną jest łączna cena obejmująca wszystkie płatności za okres rozliczeniowy. </p>
                <p>6. Gdy charakter przedmiotu Umowy nie pozwala, rozsądnie oceniając, na wcześniejsze obliczenie wysokości końcowej (ostatecznej) ceny, informacja o sposobie, w jaki cena będzie obliczana, a także o opłatach za transport, dostarczenie, usługi pocztowe oraz o innych kosztach, będzie podana na stronie internetowej Restauracji  w opisie Produktu. </p>

                <h3>6. Zakładanie Konta na stronie internetowej Restauracji </h3>
                <p>1. Aby założyć Konto na stronie internetowej Restauracji, należy wypełnić Formularz rejestracji. Niezbędne jest podanie następujących danych: imię, nazwisko, adres email, numer telefonu. </p>
                <p>2. Założenie Konta na stronie internetowej Restauracji jest darmowe. </p>
                <p>3. Logowanie się na Konto odbywa się poprzez podanie loginu i hasła ustanowionych w Formularzu rejestracji. </p>
                <p>4. Klient ma możliwość w każdej chwili, bez podania przyczyny i bez ponoszenia z tego tytułu jakichkolwiek opłat usunąć Konto poprzez wysłanie stosownego żądania do Sprzedawcy, w szczególności za pośrednictwem poczty elektronicznej lub pisemnie na adresy podane w 3. </p>

                <h3>7. Zasady składania Zamówienia </h3>
                <p>W celu złożenia Zamówienia należy: </p>
                <p>1. zalogować się do strony internetowej Restauracji (opcjonalnie); </p>
                <p>2. wybrać Produkt będący przedmiotem Zamówienia, a następnie kliknąć przycisk „Do koszyka” (lub równoznaczny); </p>
                <p>3. zalogować się lub skorzystać z możliwości złożenia Zamówienia bez rejestracji; </p>
                <p>4. jeżeli wybrano możliwość złożenia Zamówienia bez rejestracji - wypełnić Formularz zamówienia poprzez wpisanie danych odbiorcy Zamówienia oraz adresu, na który ma nastąpić dostawa Produktu, wybrać rodzaj przesyłki (sposób dostarczenia Produktu), wpisać dane do faktury, jeśli są inne niż dane odbiorcy Zamówienia, </p>
                <p>5. kliknąć przycisk “Złóż zamówienie”. </p>
                <p>6. wybrać jeden z dostępnych sposobów płatności i w zależności od sposobu płatności, opłacić zamówienie w określonym terminie, z zastrzeżeniem 8 pkt 3. </p>


                <h3>8. Oferowane metody dostawy oraz płatności </h3>
                <p>1. Klient może skorzystać z następujących metod dostawy lub odbioru zamówionego Produktu:</p>
                <p>a. Dostawa kurierska, </p>
                <p>b. Odbiór osobisty dostępny pod adresem: Gdańska 39 </p>
                <p>2. Klient może skorzystać z następujących metod płatności: </p>
                <p>a. Płatności elektroniczne </p>
                <p>3. Szczegółowe informacje na temat metod dostawy oraz akceptowalnych metod płatności znajdują się na stronie internetowej Restauracji. </p>

                <h3>9. Wykonanie umowy sprzedaży </h3>
                <p>1. Zawarcie Umowy Sprzedaży między Klientem a Sprzedawcą następuje po uprzednim złożeniu przez Klienta Zamówienia za pomocą Formularza zamówienia na stronie internetowej Restauracji zgodnie z 7 Regulaminu.</p>
                <p>2. Po złożeniu Zamówienia Restauracja niezwłocznie potwierdza jego otrzymanie oraz jednocześnie przyjmuje Zamówienie do realizacji. Potwierdzenie otrzymania Zamówienia i jego przyjęcie do realizacji następuje poprzez przesłanie przez Sprzedawcę Klientowi stosownej wiadomości e-mail na podany w trakcie składania Zamówienia adres poczty elektronicznej Klienta, która zawiera co najmniej oświadczenia Sprzedawcy o otrzymaniu Zamówienia i o jego przyjęciu do realizacji oraz potwierdzenie zawarcia Umowy Sprzedaży. Z chwilą otrzymania przez Klienta powyższej wiadomości e-mail zostaje zawarta Umowa Sprzedaży między Klientem a Sprzedawcą. </p>
                <p>3. W przypadku wyboru przez Klienta: </p>
                <p>a. płatności przelewem, płatności elektronicznych albo płatności kartą płatniczą, Klient obowiązany jest do dokonania płatności natychmiast po zawarciu Umowy Sprzedaży - w przeciwnym razie zamówienie zostanie anulowane. </p>
                <p>4. Jeżeli Klient wybrał sposób dostawy inny niż odbiór osobisty, Produkt zostanie wysłany przez Sprzedawcę w terminie wskazanym w jego opisie (z zastrzeżeniem ustępu 5 niniejszego paragrafu), w sposób wybrany przez Klienta podczas składania Zamówienia.</p>
                <p>5. Początek biegu terminu dostawy Produktu do Klienta liczy się w następujący sposób: </p>
                <p>a. W przypadku wyboru przez Klienta sposobu płatności przelewem, płatności elektroniczne lub kartą płatniczą - od dnia uznania rachunku bankowego Sprzedawcy. </p>
                <p>6. W przypadku wyboru przez Klienta odbioru osobistego Produktu, Produkt będzie gotowy do odbioru przez Klienta w terminie wskazanym w opisie Produktu. O gotowości Produktu do odbioru Klient zostanie dodatkowo poinformowany przez Sprzedawcę poprzez przesłanie stosownej wiadomości e-mail na podany w trakcie składania Zamówienia adres poczty elektronicznej Klienta. </p>
                <p>7. Początek biegu terminu gotowości Produktu do odbioru przez Klienta liczy się w następujący sposób:</p>
                <p>a. W przypadku wyboru przez Klienta sposobu płatności przelewem, płatności elektroniczne lub kartą płatniczą - od dnia uznania rachunku bankowego Sprzedawcy. </p>
                <p>8. Dostawa Produktu odbywa się wyłącznie na obszarze określonym na stronie. </p>
                <p>9. Dostawa Produktu do Klienta jest odpłatna, chyba że Umowa Sprzedaży stanowi inaczej. Koszty dostawy Produktu (w tym opłaty za transport, dostarczenie) są wskazywane Klientowi na stronie internetowej Restauracji w trakcie składania Zamówienia, w tym także w chwili wyrażenia przez Klienta woli związania się Umową Sprzedaży.</p>
                <p>10. Odbiór osobisty Produktu przez Klienta jest bezpłatny. </p>

                <h3>10. Postanowienia wstępne</h3>
                <p>1. Z uwagi na łatwo psujący się charakter produktów jaki oferuje Restauracja, Klient nie ma możliwości odstąpienia od Umowy z Restauracją jeśli chodzi o posiłki przygotowywane przez Restaurację (art. 38 pkt 4 ustawy z dnia 30 maja 2014 o prawach konsumenta (Dz.U. 2014 poz. 827 )). </p>
                <p>2. Restauracja jest uprawniona do odstąpienia od Umowy w następujących przypadkach: </p>
                <p>a. Zaistnienia siły wyższej uniemożliwiającej realizację Umowy</p>
                <p>b. Dezaktualizacji zamieszczonej na stronie internetowej Restauracji oferty, w sytuacji, kiedy Klient nie zgodził się na alternatywę dotyczącą Zamówienia złożoną przez Restaurację</p>
                <p>c. Przekazania przez klienta błędnego lub niedostępnego numeru telefonu lub adresu dostawy.</p>
                <p>3. W przypadku odstąpienia od Umowy przez Restaurację, traktuje się ją jako niezawartą. Restauracja dokona zwrotu płatności uiszczonej przez Klienta przy użyciu takiego samego sposobu zapłaty, jakiego użył Klient. </p>
                <p>4. Restauracja jest uprawniona do niezwłocznego odrzucania Zamówień składanych przez Klientów, jeżeli zachodzi uzasadniona wątpliwość co do ich poprawności lub autentyczności. W przypadku odrzucenia przez Restaurację Zamówienia już opłaconego, Restauracja zwróci wpłaconą kwotę na rachunek, z którego nastąpiła płatność. W przypadku uzasadnionego podejrzenia popełnienia przestępstwa, Restauracja jest zobowiązana do powiadomienia o tym fakcie odpowiednich organów ścigania.</p>

                <h3>11. Reklamacja i gwarancja </h3>
                <p>1. Jeżeli przedmiot zamówienia ma wadę fizyczną lub prawną, Restauracja odpowiada względem Klienta na zasadzie art. 556 i następnych Kodeksu cywilnego (rękojmia). </p>
                <p>2. Reklamacje można składać telefonicznie pod numerem telefonu: 796 215 484 lub poprzez wysłanie wiadomości e-mail na: info@mozeryba.pl. W tytule wiadomości należy podać słowo „Reklamacja”.</p>
                <p>3. Reklamacja powinna zawierać dane identyfikujące Klienta, telefon, adres e-mail, datę złożenia zamówienia, data realizacji zamówienia, wskazany adres realizacji zamówienia oraz szczegółowy opis składanych zastrzeżeń.</p>
                <p>4. Jeżeli podane w reklamacji dane lub informacje są niepełne lub dla potrzeby pełnego wyjaśnienia Restauracja wystąpi o ich uzupełnienie, jeszcze przed rozpatrzeniem reklamacji. Czas rozpatrzenia reklamacji ulega przedłużeniu do momentu uzupełniania danych przez Klienta składającego reklamację. </p>
                <p>5. Klient otrzyma informację o sposobie rozpatrzenia reklamacji w terminie 14 dni od dnia zgłoszenia żądania przez Klienta. </p>
                <p>6. Informacja o sposobie rozstrzygnięcia reklamacji zostanie przekazana Klientowi na adres poczty elektronicznej, za pomocą którego została zgłoszona reklamacja lub na adres poczty elektronicznej podany podczas rozmowy telefonicznej, podczas której została zgłoszona reklamacja.</p>
                <p>7. Sprzedawca nie zobowiązuje się w ramach niniejszego Regulaminu do korzystana z pozasądowego rozwiązywania sporów, o których mowa w ustawie z dnia 23 września 2016 r. o pozasądowym rozwiązywaniu sporów konsumenckich. </p>

                <h3>12. Dane osobowe w stronie internetowej Restauracji </h3>
                <p>1. Administratorem danych osobowych Klientów zbieranych za pośrednictwem stronie internetowej Restauracji jest Restauracja. </p>
                <p>2. Dane osobowe Klientów zbierane przez administratora za pośrednictwem stronie internetowej Restauracji zbierane są w celu realizacji Umowy Sprzedaży, a jeżeli Klient wyrazi na to zgodę - także w celu marketingowym. </p>
                <p>3. Odbiorcami danych osobowych Klientów stronie internetowej Restauracji mogą być:</p>
                <p>a. W przypadku Klienta, który korzysta w stronie internetowej Restauracji ze sposobu dostawy przesyłką kurierską, Administrator udostępnia zebrane dane osobowe Klienta wybranemu przewoźnikowi lub pośrednikowi realizującemu przesyłki na zlecenie Administratora. </p>
                <p>b. W przypadku Klienta, który korzysta w stronie internetowej Restauracji internetowym ze sposobu płatności elektronicznych lub kartą płatniczą Administrator udostępnia zebrane dane osobowe Klienta, wybranemu podmiotowi obsługującemu powyższe płatności w stronie internetowej Restauracji. </p>
                <p>4. Klient ma prawo dostępu do treści swoich danych oraz ich poprawiania. </p>
                <p>5. Podanie danych osobowych jest dobrowolne, aczkolwiek niepodanie wskazanych w Regulaminie danych osobowych niezbędnych do zawarcia Umowy Sprzedaży skutkuje brakiem możliwości zawarcia tejże umowy.</p>

                <h3>13. Postanowienia końcowe </h3>
                <p>1. Umowy zawierane poprzez stronę internetową Restauracji zawierane są w języku polskim. </p>
                <p>2. Sprzedawca zastrzega sobie prawo do dokonywania zmian Regulaminu z ważnych przyczyn to jest: zmiany przepisów prawa, zmiany sposobów płatności i dostaw - w zakresie, w jakim te zmiany wpływają na realizację postanowień niniejszego Regulaminu. O każdej zmianie Sprzedawca poinformuje Klienta z co najmniej 7 dniowym wyprzedzeniem. </p>
                <p>3. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie powszechnie obowiązujące przepisy prawa polskiego, w szczególności: Kodeksu cywilnego; ustawy o świadczeniu usług drogą elektroniczną; ustawy o prawach konsumenta, ustawy o ochronie danych osobowych. </p>
                <p>4. Klient ma prawo skorzystać z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń. W tym celu może złożyć skargę za pośrednictwem unijnej platformy internetowej ODR dostępnej pod adresem: http://ec.europa.eu/consumers/odr/. </p>

                </div>
        )
    }
}



export default Regulations;