import React from 'react';

const ClockIcon = ({
    className ="",
    classNamePath =""
}) =>(
<svg className={className} xmlns="http://www.w3.org/2000/svg" width="298.103" height="298.103" viewBox="0 0 298.103 298.103">
  <path className={`${classNamePath || ""}`} id="clock" d="M157.051,8A149.051,149.051,0,1,0,306.1,157.051,149.026,149.026,0,0,0,157.051,8Zm34.318,210.415L138.36,179.89a7.259,7.259,0,0,1-2.945-5.83V72.909a7.233,7.233,0,0,1,7.212-7.212h28.849a7.233,7.233,0,0,1,7.212,7.212v82.76l38.164,27.767a7.208,7.208,0,0,1,1.563,10.1l-16.949,23.319a7.262,7.262,0,0,1-10.1,1.563Z" transform="translate(-8 -8)"/>
</svg>);

export default ClockIcon;