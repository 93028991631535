import React from 'react';
import './Accordion.css';
import Chevron from './Chevron';
import AccordionItem from './AccordionItem.js';
import { connect } from 'react-redux';
import { getMenuItems } from '../../js/actions/index';

class Accordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setActive: "",
            setHeight: "0px",
            setRotate: "accordion__icon"
        };

        this.content = React.createRef();

        this.toggleAccordion = this.toggleAccordion.bind(this);
    }

    componentDidMount() {
        this.props.getMenuItems();
    }

    toggleAccordion(){
        this.setState({setActive: (this.state.setActive === "" ? "active" : "")});
        this.setState({setHeight: (this.state.setActive === "active" ? "0px" : `${this.content.current.scrollHeight}px`)});
        this.setState({setRotate: (this.state.setActive === "active" ? "accordion__icon" : "accordion__icon rotate")});
    }

    render(){

        return(
            <div key={this.props.id} className="accordion__section">
                <button className={`accordion ${this.state.setActive}`} onClick={this.toggleAccordion}>
                    <div className="accordion__photo">
                        <img src={process.env.REACT_APP_API + this.props.image} alt="" className="items__image"/>
                    </div>
                    <div className="accordion__data">
                        <p className="accordion__title">{this.props.name}</p>
                    </div>
                    <Chevron className={`${this.state.setRotate}`} width={28} height={28} fill={"#777"} />
                </button>

                <div ref={this.content} style={{ maxHeight: `${this.state.setHeight}` }} className="accordion__content">
                    {this.props.menuItems.filter((category) => category.category_id === this.props.id).map(
                        meal => (
                            meal.isEndabled === true ?
                            <AccordionItem key={meal._id} category={meal.category_id} price={meal.price} desc={meal.desc} name={meal.name} id={meal._id} image={meal.image} />
                            :
                            " "
                        ))}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        menuItems: state.menuItems
    };
}

export default connect(mapStateToProps, {getMenuItems })(Accordion);