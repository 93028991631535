import React from 'react';
import { connect } from "react-redux";
import './OrderStatus.css';

class RealizationMessageCash extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            etap: 0,
            message: ''
        };
        this.realizationEtap = this.realizationEtap.bind(this);
    }

    componentDidMount(){
        this.realizationEtap()
    }

    componentDidUpdate(prevProps) {
        if (this.props.order.status !== prevProps.order.status) {
            this.realizationEtap()
        }
      }

    realizationEtap(){
        if(this.props.order.status === "Przyjęte"){
            this.setState({etap: 1, message: 'Twoje zamówienie zostało przyjęte do realizacji'})
        }
        else if(this.props.order.status === "Przygotowanie"){
            this.setState({etap: 2, message: 'Twoje zamówienie jest przygotowywane'})
        }
        else if(this.props.order.status === "Gotowe do odbioru"){
            this.setState({etap: 3, message: 'Twoje zamówienie jest gotowe!'})
        }
        else if(this.props.order.status === "Zrealizowane"){
            this.setState({etap: 4, message: 'Twoje zamówienie zostało wydane. Smacznego!'})
        }
        else if(this.props.order.status === "Zaplanowane"){
            this.setState({etap: 5, message: 'Twoje zamówienie zostało zaplanowane. Informacje o zamówieniu będą dostępne niedługo przed czasem realizacji.'})
        }
    }

    render(){
        return(
            <h3>{this.state.message}</h3>
        );
    }
}
function mapStateToProps(state){
    return {
        order: state.activeOrder,
    };
}

export default connect(mapStateToProps)(RealizationMessageCash);